import { Divider, Typography, Modal, message } from "antd"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { Product, Order, InvoiceHead, InvoiceItem } from "../../../models"
import useViewport from "../../../hooks/useViewport"
import html2canvas from "html2canvas"
import Barcode from "react-barcode"
import ReactToPrint, { useReactToPrint } from "react-to-print"
import { loadAllInvoices, loadCustomers, serveCustomer, updateCustomer } from "../../../redux/Customer/slice"
import { clearCart } from "../../../redux/Checkout/slice"
import { reloadCustomerScreen } from "../../../utils/customerscreen-functions"
import { useNavigate } from "react-router-dom"
import PaymentTotalReceiptSection from "./PaymentTotalReceiptSection"
import { listCustomers, getCustomerByCustomerCode } from "../../../api/customer"
import { listInvoices, listNoneRefundInvoices } from "../../../api/invoice"
import { customerInDBToCustomer, invoiceInDBsToLocalInvoices } from "../../../utils/Global"
import { clearPaymentProcedure, setPaymentType } from "../../../redux/Payment/slice"
// import { ExamplePOSReceipt } from "../../../constants/data"
import { stringify } from "querystring"
import { getConfiguration } from "../../../utils/configurate"
import { PostDrawOpen } from "../../../api/drawOpen";
import PrintReceipt from "./PrintReceipt"
import { generateTableCustomKorean } from "../../../utils/customTableKorean"
import ScrollButtons from "../../common/ScrollButtons"
// import { getUserInfo } from "../../../api/user"
import ActionConfirmModal from "../../../components/common/ActionConfirmModal"


const PosConfig = getConfiguration("ReceiptModal")
const { machineID: MachineID, warehouseName, warehouseAddress: Address, warehousePhone: Telephone, serverAddress: ServerAddress, port: Port, abn: ABN } = PosConfig

const { Title } = Typography
const logo = require("../../../assets/png/logo.png")
const exampleReceipt = require("../../../assets/png/pos-example.png")

type Props = {
  isVisible: boolean
  invoice: InvoiceHead
  // paymentMethod: "cash" | "card" | "royalpay"
  setVisibility?: (value: boolean) => void
  showPaymentTotal?: boolean
  onCancel: () => void
  isSale?: boolean
}

export default function ReceiptModal({ invoice, isVisible, setVisibility, onCancel, showPaymentTotal, isSale = true }: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width, height } = useViewport()
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total } = Checkout
  const [curTotal, setCurTotal] = useState<any>(total)
  const [subTotal, setSubTotal] = useState<number>(0)
  const [savedAmount, setSavedAmount] = useState<number>(0)
  const [redeemPoints, setRedeemPoints] = useState<number>(0)
  // const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  // const { paymentTotal, paymentTotal1, paymentTotal2, paymentType, paymentStep, paymentMethod1, paymentMethod2, paymentAmount1, paymentAmount2 } = Payment
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const UserName = useRef(invoice.Operator ? invoice.Operator.Name : 'Supervisor')
  const { servingCustomer } = Customer
  console.log('发票的Invoice内容----->', invoice)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [canvasUrl, setCanvasUrl] = useState<any>()
  const [posReceipt, setPosReceipt] = useState(invoice.ReceiptCustomerCopy?.split(","))
  // const [posReceipt, setPosReceipt] = useState(Payment.customerCopy)
  const componentRef = useRef(null)
  const recvAcct: any = invoice?.RecvAccts?.filter(acct => !acct.Notes && acct.PayBy !== "ROUNDING")
  console.log('invoice?.RecvAcct是：：', recvAcct, invoice?.RecvAccts)
  const paymentMethod = recvAcct[0].PayBy.replaceAll(" ", "")
  console.log("paymentMethod", paymentMethod, recvAcct[0])
  const paymentMethod1 = recvAcct[0].PayBy.replaceAll(" ", "")
  const paymentMethod2 = recvAcct[1] ? recvAcct[1].PayBy.replaceAll(" ", "") : undefined
  const paymentTotal = recvAcct[0]
  const paymentTotal1 = recvAcct[0]
  const paymentTotal2 = recvAcct[1] ? recvAcct[1] : undefined
  console.log('recvAcct内容----->', recvAcct, posReceipt)
  const CardType = "MASTERCARD,VISA,DEBITCARD"
  console.log("支付。。。", "1" + paymentMethod + "1", paymentMethod1, paymentMethod2)
  const printerWidth = 42 // Default width of the printer is set to 48
  const emptyChars = '                                          '//长度为42的空字符串
  const [pageUpDown, setPageUpDown] = useState<number>(1)
  const [oldPage, setOldPage] = useState(1)
  const [isInitPrinterAlertModal, setIsInitPrinterAlertModal] = useState<boolean>(false)

  useEffect(() => {
    dispatch(clearCart({}))
    dispatch(serveCustomer({ customer: null }))
    dispatch(setPaymentType({ paymentType: "oneOff" }))//初始化付款类型为一次性
    if ((paymentMethod === 'CASH' || paymentMethod1 === 'CASH' || paymentMethod2 === 'CASH') && isSale) {
      //@ts-ignore
      if (window?.electronAPI) {
        //@ts-ignore
        console.log('openDrawComponent', window?.electronAPI?.openDrawComponent)
        //@ts-ignore
        window?.electronAPI?.openDrawComponent((response) => {
          console.log('Todo add sale open draw log: ', response);
        });
      }
      const openRecord: any = {
        Reasons: `InvoiceNo: ${invoice.InvoiceNo}`
      };
      PostDrawOpen(openRecord)
    }
    //汇总产品金额得到subtotal和saved
    if (invoice.InvoiceItems) {
      let savedAmount: number = 0
      const itemAmountArray = invoice.InvoiceItems.map(item => {
        console.log('汇总发票明细', item)
        if (item.Product?.SalesPrice1 !== item.Price) {
          savedAmount = savedAmount + (Number(((item.Product?.SalesPrice1 ?? 0) ).toFixed(2)) *((100+(item.Product?.GSTStatus? item.GSTRate : 0))/100) * item.Qty - Number((item.Price * (item.Discount ? (1 - item.Discount / 100) : 1) * item.Qty).toFixed(2)))
          // console.log('累计saved', savedAmount, item.Product?.SalesPrice1,Number(((item.Product?.SalesPrice1 ?? 0) ).toFixed(2)) *((100+(item.Product?.GSTStatus? item.GSTRate : 0))/100), Number((item.Price * (item.Discount ? (1 - item.Discount / 100) : 1))) )
        }
        return Number((item.Price * (item.Discount ? (1 - item.Discount / 100) : 1) * item.Qty).toFixed(2))
      }
      )
      // const itemAmountArray = invoice.InvoiceItems.map(item => Number(item.Price.toFixed(2)))
      setSubTotal(itemAmountArray.reduce(function (prev, curr, idx, arr) {
        return prev + curr;
      }))
      setSavedAmount(savedAmount)
      console.log('汇总产品金额得到subtotal', invoice.InvoiceItems, itemAmountArray)
    }
    //得到RedeemPoints
    invoice.LoyaltyPoints?.forEach(point => {
      if (point.RedeemPoints > 0) {
        setRedeemPoints(point.RedeemPoints)
      }
    })
    // //得到invoice的cashier
    // if (invoice.OpFirebaseUid) {
    //   const setGetUserName = () => new Promise(async (resolve, reject) => {
    //     const result: any | undefined = await getUserInfo({OpFirebaseUid: invoice.OpFirebaseUid})
    //     console.log("发票创建用户 result: ", result, invoice.OpFirebaseUid)
    //     resolve("finish")
    //   })
    //   setGetUserName()
    //     .then(() => {
    //       // navigate("/checkout")
    //       // reloadCustomerScreen()
    //     })
    //     .catch((e) => console.log('发票创建用户信息获取错误', e))
    // }

  }, [])

  const clearCartTrigger = () => {
    const setClearCartPromise = () => new Promise(async (resolve, reject) => {
      const customer = servingCustomer
      dispatch(serveCustomer({ customer: null }))
      dispatch(clearCart({}))
      dispatch(clearPaymentProcedure({}))
      dispatch(setPaymentType({ paymentType: "oneOff" }))//初始化付款类型为一次性
      // TO DO: update customer and invoice instead of loading all of them
      if (servingCustomer) {
        const customersData = await getCustomerByCustomerCode(customer.CustomerCode)
        if (customersData) {
          const newCustomer = customerInDBToCustomer([customersData])[0]
          dispatch(updateCustomer({ customer: newCustomer }))
        }
      }
      const invoicesData = await listNoneRefundInvoices()
      // const customersData = await listCustomers()
      // const customers = customerInDBToCustomer(customersData.data)
      const invoices = invoiceInDBsToLocalInvoices(invoicesData.data)

      // dispatch(loadCustomers({ customers: customers }))
      dispatch(loadAllInvoices({ invoices: invoices }))
      resolve("finish")
    })
    setClearCartPromise()
      .then(() => {
        // navigate("/checkout")
        // reloadCustomerScreen()
      })
      .catch((e) => console.log(e))
  }

  const handlePrint = () => {
    console.log('forwarding print request to the main process...');
    const headArray: any[] = []
    //生成头部数据
    let headSting: any = []
    headSting.push({
      text: `${warehouseName}`,
      align: 'CENTER',
      width: 1,
      style: 'B'
    })
    headArray.push({ headSting })
    headSting = []
    headSting.push({
      text: `${Address}`,
      align: 'CENTER',
      width: 1,
      style: 'B'
    })
    headArray.push({ headSting })
    headSting = []
    headSting.push({
      text: `PHONE: ${Telephone}`,
      align: 'CENTER',
      width: 1,
      style: 'B'
    })
    headArray.push({ headSting })
    headSting = []
    headSting.push({
      text: `TAX INVOICE - ABN: ${ABN ? ABN : 85613150691}`,
      align: 'CENTER',
      width: 1,
      style: 'B'
    })
    headArray.push({ headSting })
    headSting = []

    const itemArray: any[] = []

    //生成明细数据
    invoice.InvoiceItems.forEach((item: any) => {
      let itemSting: any = []

      if (item?.Product?.Description1) {//特价名称
        itemArray.push({ itemSting: [{ text: item.Product.Description1, align: 'LEFT', width: 1, encoding: '949' }] })
      }

      let productName = ''
      // generateTableCustomKorean(itemArray, item)
      // Update: move korean to front
      if (item.Product?.KoreanName) {
        productName += item.Product?.KoreanName + ' '
      }
      productName += item.Product?.Description
      let encoding = 'EUC-KR'//"949"
      if (!item.Product?.KoreanName && item.Product?.ChineseName) {
        productName += item.Product?.ChineseName
        encoding = 'GB18030'
        // } else if (item.Product?.Description3) {
        //   productName += item.Product?.Description3
        //   encoding = 'EUC-KR'
        //   // encoding = 'EUC-JP'
      }
      const lengthInBytes = new Blob([productName]).size
      const empLength = Math.max(printerWidth - productName.length - (lengthInBytes - productName.length) / 2, 0)
      itemSting.push({//产品名称
        text: `${productName}${emptyChars.substring(0, empLength)}`,
        align: 'LEFT',
        width: 1,
        encoding
        // style: 'B'
      })
      // itemSting.push({
      //   text: `$ ${item.Price?.toFixed(2)}`,
      //   align: 'RIGHT',
      //   width: 0.3,
      //   // style: 'B'
      // })
      // itemArray.push({ itemSting })
      itemArray.push({ itemSting, encoding: '949' })
      itemSting = []// || item.Product?.ChineseName || item.Product?.Description3
      // if (item.Product?.KoreanName) {
      //   itemSting.push({
      //     text: `${item.Product?.KoreanName}`,
      //     align: 'LEFT',
      //     width: 0.7,
      //   })
      //   // itemSting.push({
      //   //   text: `$ ${item.Price?.toFixed(2)}`,
      //   //   align: 'RIGHT',
      //   //   width: 0.3,
      //   //   // style: 'B'
      //   // })
      //   itemArray.push({ itemSting, encoding: '949' })
      //   itemSting = []
      // }
      // else if (item.Product?.ChineseName) {
      //   itemSting.push({
      //     // text: `中文`,
      //     text: `${item.Product?.ChineseName}`,
      //     align: 'LEFT',
      //     width: 1,
      //   })

      //   itemArray.push({ itemSting, encoding: 'GB18030' })
      //   itemSting = []

      // } else if (item.Product?.Description3) {
      //   itemSting.push({
      //     text: `${item.Product?.Description3}`,
      //     align: 'LEFT',
      //     width: 1,
      //   })

      //   itemArray.push({ itemSting, encoding: 'EUC-JP' })
      //   itemSting = []
      // }

      if (item.PriceKind && (item.PriceKind && (item.PriceKind.indexOf('specialprice') > -1 || item.PriceKind.indexOf('specialdiscount') > -1 || item.PriceKind.indexOf('reductionprice') > -1))) {
        itemSting.push({
          text: `${item.PriceKind.slice(item.PriceKind.indexOf('/') + 1).replace("/", " ")}`,
          // text: `${item.PriceKind.slice(13).replace("/", " ")}`,
          align: 'LEFT',
          width: 1,
          // style: 'B'
        })
        // itemSting.push({
        //   text: ` `,
        //   align: 'RIGHT',
        //   width: 0.3,
        //   // style: 'B'
        // })
        itemArray.push({ itemSting })
        itemSting = []
      }
      if (item.PriceKind && item.PriceKind.indexOf('Promotion') > -1) {
        itemSting.push({
          // text: `${item.PriceKind.indexOf('promotion/') !== -1 ?
          text: `${item.PriceKind.indexOf('Promotion') !== -1 ?
            item.PriceKind.slice(item.PriceKind.indexOf('Promotion'))
            :
            item.PriceKind.slice(item.PriceKind.indexOf('Promotion') + 10)
            }`,
          align: 'LEFT',
          width: 1,
          // style: 'B'
        })
        // itemSting.push({
        //   text: ` `,
        //   align: 'RIGHT',
        //   width: 0.3,
        //   // style: 'B'
        // })
        itemArray.push({ itemSting })
        itemSting = []
      }
      //处理单价数量和总价行
      let priceTextLine = item.Discount ?
        "@$(" + item.Price.toFixed(2) + " -" + item.Discount.toFixed(0) + "%)" + (item.Price * (item.Discount ? (1 - item.Discount / 100) : 1))?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "")
        // "@$" + (item.Price * (item.Discount ? (1 - item.Discount / 100) : 1))?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "") + " (-" + item.Discount.toFixed(0) + "%)"
        // "@$" + item.UnitCost?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "") + " (-" + item.Discount.toFixed(0) + "%)"
        :
        "@$" + item.Price.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "")
      // "@$" + item.UnitCost?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "")
      let leftLength = printerWidth - priceTextLine.length
      const itemTotal = (item.Price * (item.Discount ? (1 - item.Discount / 100) : 1) * item.Qty).toFixed(2)
      if (leftLength > 0 && itemTotal.length < leftLength) {
        priceTextLine += emptyChars.substring(0, leftLength - (itemTotal.length + 1)) + "$" + itemTotal
      } else {
        priceTextLine += '\CR'
        priceTextLine += emptyChars.substring(0, printerWidth - (itemTotal.length + 1)) + "$" + itemTotal
      }
      itemSting.push({
        text: priceTextLine,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })
      // itemSting.push({
      //   text: `${item.Discount ?
      //     "  @$" + item.UnitCost?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "") + " (-" + item.Discount.toFixed(0) + "%)"
      //     :
      //     "  @$" + item.UnitCost?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "")
      //     }`,
      //   align: 'LEFT',
      //   width: 0.7,
      //   // style: 'B'
      // })
      // itemSting.push({
      //   text: `$ ${item.Price?.toFixed(2)}`,
      //   align: 'RIGHT',
      //   width: 0.3,
      //   // style: 'B'
      // })
      itemArray.push({ itemSting })
      itemArray.push({
        itemSting: [{//插入空行
          text: ` `,
          width: 0.7,
        }]
      })
    })
    //省钱提示移到此处
    if (savedAmount > 0) {
      let saveLineText = `You saved ${savedAmount.toFixed(2)}`
      // let saveValue = ``
      saveLineText += emptyChars.substring(0, printerWidth - saveLineText.length)
      // saveLineText += emptyChars.substring(0, printerWidth - saveLineText.length - saveValue.length) + saveValue
      let itemSting: any = []
      itemSting.push({
        text: saveLineText,
        // text: `You saved ${curTotal.discountAmount?.toFixed(2)}`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })
      itemArray.push({ itemSting })
    }
    // if (curTotal.discountAmount && curTotal.discountAmount > 0) {
    //   let saveLineText = `You saved ${curTotal.discountAmount?.toFixed(2)}`
    //   // let saveValue = ``
    //   saveLineText += emptyChars.substring(0, printerWidth - saveLineText.length)
    //   // saveLineText += emptyChars.substring(0, printerWidth - saveLineText.length - saveValue.length) + saveValue
    //   let itemSting: any = []
    //   itemSting.push({
    //     text: saveLineText,
    //     // text: `You saved ${curTotal.discountAmount?.toFixed(2)}`,
    //     align: 'LEFT',
    //     width: 1,
    //     // style: 'B'
    //   })
    //   itemArray.push({ itemSting })
    // }

    //生成汇总数据
    const subTotalArray: any[] = []
    let subTotalSting: any = []
    let subTotallineText = `${invoice.InvoiceItems.length} SUBTOTAL`
    subTotallineText += emptyChars.substring(0, printerWidth - subTotallineText.length - subTotal.toFixed(2).length - 1) + `$${subTotal.toFixed(2)}`
    subTotalSting.push({
      text: subTotallineText,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })
    // subTotalSting.push({
    //   text: `$ ${subTotal.toFixed(2)}`,
    //   align: 'RIGHT',
    //   width: 0.3,
    //   // style: 'B'
    // })
    subTotalArray.push({ subTotalSting })
    subTotalSting = []

    if (invoice.OtherCharge) {
      subTotallineText = `$SURCHARGE`
      let surChargeText = `$${invoice.OtherCharge.toFixed(2)}`
      subTotallineText += emptyChars.substring(0, printerWidth - subTotallineText.length - surChargeText.length - 2) + surChargeText

      subTotalSting.push({
        text: subTotallineText,
        // text: `${redeemPoints} POINTS REDEEM`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })
      // subTotalSting.push({
      //   text: `-$ ${redeemPoints / 200}`,
      //   align: 'RIGHT',
      //   width: 0.3,
      //   // style: 'B'
      // })
      subTotalArray.push({ subTotalSting })
      subTotalSting = []
    }

    if (redeemPoints) {
      subTotallineText = `${redeemPoints} POINTS REDEEM`
      let redeemText = `-$${redeemPoints / 200}`
      subTotallineText += emptyChars.substring(0, printerWidth - subTotallineText.length - redeemText.length - 2) + redeemText

      subTotalSting.push({
        text: subTotallineText,
        // text: `${redeemPoints} POINTS REDEEM`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })
      // subTotalSting.push({
      //   text: `-$ ${redeemPoints / 200}`,
      //   align: 'RIGHT',
      //   width: 0.3,
      //   // style: 'B'
      // })
      subTotalArray.push({ subTotalSting })
      subTotalSting = []
    }
    subTotallineText = `TOTAL`
    subTotallineText += emptyChars.substring(0, printerWidth - subTotallineText.length - (invoice?.PaidAmount ? invoice?.PaidAmount.toFixed(2).length : 3) - 1) + `$${invoice.PaidAmount?.toFixed(2)}`
    // subTotallineText += emptyChars.substring(0, printerWidth - subTotallineText.length - (invoice?.Amount ? invoice?.Amount.toFixed(2).length : 3) - 1) + `$${invoice.Amount?.toFixed(2)}`

    subTotalSting.push({
      text: subTotallineText,
      // text: `TOTAL`,
      align: 'LEFT',
      width: 1,
      style: 'B'
    })
    // subTotalSting.push({
    //   text: `$ ${invoice.Amount?.toFixed(2)}`,
    //   align: 'RIGHT',
    //   width: 0.25,
    //   style: 'B'
    // })
    subTotalArray.push({ subTotalSting })
    subTotalSting = []
    subTotallineText = `GST Included in TOTAL`
    subTotallineText += emptyChars.substring(0, printerWidth - subTotallineText.length - (invoice.GST ? invoice.GST.toFixed(2).length : 3) - 1) + `$${invoice.GST?.toFixed(2)}`

    subTotalSting.push({
      text: subTotallineText,
      // text: `GST Included in TOTAL`,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })
    // subTotalSting.push({
    //   text: `$ ${invoice.GST?.toFixed(2)}`,
    //   align: 'RIGHT',
    //   width: 0.3,
    //   // style: 'B'
    // })
    subTotalArray.push({ subTotalSting })
    // subTotalSting = []
    //支付部分
    const payArray: any[] = []
    let paySting: any = []
    //如果有刷卡，显示刷卡信息
    if (CardType.indexOf(paymentMethod) > -1 || CardType.indexOf(paymentMethod1) > -1 || CardType.indexOf(paymentMethod2) > -1) {
      // if (paymentMethod === "card" || paymentMethod1 === "card" || paymentMethod2 === "card") {
      posReceipt?.forEach((line: string) => {
        paySting.push({
          text: line,
          align: 'CENTER',
          width: 1,
          // style: 'B'
        })
        payArray.push({ paySting })
        paySting = []
      })

    }
    //显示支付方式和金额
    let paylineText = ""
    let payValue = ""
    if (recvAcct.length === 1) {
      paylineText = `${paymentMethod?.toUpperCase()}`
      payValue = `$${paymentMethod === "CASH" ? (recvAcct[0]?.PaidAmount || 0).toFixed(2) : invoice.PaidAmount?.toFixed(2)}`
      paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue

      paySting.push({
        text: paylineText,
        // text: `${paymentMethod?.toUpperCase()}`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })
      // paySting.push({
      //   text: `$ ${paymentMethod === "CASH" ? (recvAcct[0]?.PaidAmount || 0).toFixed(2) : invoice.Amount?.toFixed(2)}`,
      //   // text: `$ ${paymentMethod === "cash" ? paymentTotal.depositAmount? paymentTotal.depositAmount.toFixed(2): (invoice.RecvAccts?.filter(item => item.Notes === null)[0]?.PaidAmount || 0).toFixed(2) : invoice.Amount?.toFixed(2)}`,
      //   align: 'RIGHT',
      //   width: 0.5,
      //   // style: 'B'
      // })
      payArray.push({ paySting })
      paySting = []
      if (paymentMethod === "CASH") {
        paylineText = `Change`
        payValue = `$ -${Math.abs(invoice.RecvAccts?.filter(item => item.Notes === 'CHANGE')[0]?.PaidAmount || 0).toFixed(2)}`
        paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
        paySting.push({
          text: paylineText,
          // text: `Change`,
          align: 'LEFT',
          width: 1,
          // style: 'B'
        })
        // paySting.push({
        //   text: `$ -${Math.abs(invoice.RecvAccts?.filter(item => item.Notes === 'CHANGE')[0]?.PaidAmount || 0).toFixed(2)}`,
        //   align: 'RIGHT',
        //   width: 0.5,
        //   // style: 'B'
        // })
        payArray.push({ paySting })
        paySting = []
        if (invoice.RecvAccts?.some(acct => acct.PayBy === "ROUNDING")) {
          paylineText = `Rounding`
          payValue = `$${Math.abs(invoice.RecvAccts?.filter(item => item.PayBy === 'ROUNDING' && item.PaymentID === paymentTotal.PaymentID)[0]?.PaidAmount || 0).toFixed(2)}`
          paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
          paySting.push({
            text: paylineText,
            // text: `Rounding`,
            align: 'LEFT',
            width: 1,
            // style: 'B'
          })
          payArray.push({ paySting })
          paySting = []
        }
      }
    } else {
      paylineText = `Split Payment 1: ${paymentMethod1?.toUpperCase()}`
      payValue = `$ ${paymentTotal1?.PaidAmount.toFixed(2)}`
      paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
      paySting.push({
        text: paylineText,
        // text: `Split Payment 1: ${paymentMethod1?.toUpperCase()}`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })

      payArray.push({ paySting })
      paySting = []
      if (paymentMethod1 === "CASH") {
        paylineText = `Change`
        payValue = `$ -${Math.abs(invoice.RecvAccts?.filter(item => item.Notes === 'CHANGE' && item.PaymentID === paymentTotal1.PaymentID)[0]?.PaidAmount || 0).toFixed(2)}`
        paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
        paySting.push({
          text: paylineText,
          // text: `Change`,
          align: 'LEFT',
          width: 1,
          // style: 'B'
        })

        payArray.push({ paySting })
        paySting = []
      }
      paylineText = `Split Payment 2: ${paymentMethod2?.toUpperCase()}`
      payValue = `$ ${paymentTotal2?.PaidAmount.toFixed(2)}`
      paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
      paySting.push({
        text: paylineText,
        // text: `Split Payment 2: ${paymentMethod2?.toUpperCase()}`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })

      payArray.push({ paySting })
      paySting = []
      if (paymentMethod2 === "CASH") {
        paylineText = `Change`
        payValue = `$ ${Math.abs(invoice.RecvAccts?.filter(item => item.Notes === 'CHANGE' && item.PaymentID === paymentTotal2.PaymentID)[0]?.PaidAmount || 0).toFixed(2)}`
        paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
        paySting.push({
          text: paylineText,
          // text: `Change`,
          align: 'LEFT',
          width: 1,
          // style: 'B'
        })

        payArray.push({ paySting })
        paySting = []
      }
    }
    if (invoice.Customer && invoice.RewardPoints) {
      paylineText = `Points Earned in This Shop`
      payValue = `${invoice.RewardPoints}`
      paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
      paySting.push({
        text: paylineText,
        // text: `Points Earned in This Shop`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })

      payArray.push({ paySting })
    }

    //客户部分
    const custArray: any[] = []
    let custSting: any = []
    let custlineText = ""
    let custValue = ""
    const dateString: string = new Date(`${invoice && invoice.InvoiceDate}`).toLocaleString()
    custlineText = `Date: ${dateString}`
    // custlineText = `Date: ${new Date().toLocaleDateString()}`
    custValue = `No: ${invoice?.InvoiceNo}`
    custlineText += emptyChars.substring(0, printerWidth - custlineText.length - custValue.length) + custValue
    custSting.push({
      text: custlineText,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })

    custArray.push({ custSting })
    custSting = []

    custlineText = `Cashier: ${UserName.current}`
    custValue = `Register: ${MachineID}`
    custlineText += emptyChars.substring(0, printerWidth - custlineText.length - custValue.length) + custValue
    custSting.push({
      text: custlineText,
      // text: `Cashier: Supervisor`,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })
    custArray.push({ custSting, encoding: '949' })
    custSting = []
    custlineText = `Customer: ${invoice.Customer ? `${invoice.Customer.CustomerName}[${invoice.Customer?.Mobile ?? invoice.Customer?.Telephone ?? invoice.Customer?.CustomerCode ?? ''}]` : 'Cash Customer'}`
    // custValue = `Points: ${(invoice.CurrentPoints? invoice.CurrentPoints : 0) + (invoice.RewardPoints? invoice.RewardPoints : 0) - redeemPoints}`
    // custValue = `Points: ${invoice.Customer && invoice.RewardPoints ? invoice.RewardPoints : 0}`
    custlineText += emptyChars.substring(0, printerWidth - custlineText.length)
    // custlineText += emptyChars.substring(0, printerWidth - custlineText.length - custValue.length) + custValue

    custSting.push({
      text: custlineText,
      // text: `Customer: ${invoice.Customer ? invoice.Customer.CustomerName : 'Cash Customer'}`,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })

    custArray.push({ custSting, encoding: '949' })
    custSting = []
    custlineText = `Points: ${(invoice.CurrentPoints ? invoice.CurrentPoints : 0) + (invoice.RewardPoints ? invoice.RewardPoints : 0) - redeemPoints}`
    custlineText += emptyChars.substring(0, printerWidth - custlineText.length)
    custSting.push({
      text: custlineText,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })

    custArray.push({ custSting, encoding: '949' })
    custSting = []

    // if (curTotal.discountAmount && curTotal.discountAmount > 0) {
    //   custlineText = `You saved ${curTotal.discountAmount?.toFixed(2)}`
    //   custValue = ``
    //   custlineText += emptyChars.substring(0, printerWidth - custlineText.length - custValue.length) + custValue

    //   custSting.push({
    //     text: custlineText,
    //     // text: `You saved ${curTotal.discountAmount?.toFixed(2)}`,
    //     align: 'LEFT',
    //     width: 1,
    //     // style: 'B'
    //   })
    //   custArray.push({ custSting })
    //   custSting = []
    // }


    const printJson = {
      headArray,
      itemArray,
      subTotalArray,
      payArray,
      custArray,

      invoiceNo: invoice.InvoiceNo,
    }

    console.log('printJson', printJson)

    //@ts-ignore
    window.electronAPI.printComponentV2(printJson, (response) => {
      console.log('printComponentV2 Response 1: ', response);
      if (response === 'failed') {
        setIsInitPrinterAlertModal(true)
      }
    });
  };

  const CompanyInfo = () => (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <img src={process.env.PUBLIC_URL + "/images/logo.png"} style={{ marginBottom: 10, width: 120 }} />
      {/* <img src={window.location.origin + "/images/logo.png"} style={{ marginBottom: 10, width: 120, height: 70 }} /> */}
      <Typography style={{ fontSize: 16, fontWeight: "bold" }}>{`${warehouseName}(${Address})`}</Typography>
      <Typography style={{ fontSize: 16, fontWeight: "bold" }}>PHONE: {Telephone}</Typography>
      <Typography style={{ fontSize: 16, fontWeight: "bold" }}>TAX INVOICE - ABN: 85613150691</Typography>
    </div>
  )

  const InvoiceItems = () => (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      {
        invoice.InvoiceItems.map((item: any) => {
          return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 10 }}>
              <div>
                {
                  (item?.Product?.Description1)
                    ? <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ marginLeft: 15, width: 250, margin: 0 }}>{item?.Product?.Description1}</Typography.Paragraph>
                    : null
                }
                {
                  (item.Product?.KoreanName) &&
                  // item.Product?.nameKorean &&
                  <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ marginLeft: 15, width: 250, margin: 0 }}>{item.Product?.KoreanName}</Typography.Paragraph>
                }
                <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ marginLeft: 15, width: 250, margin: 0 }}>{item.Product?.Description}</Typography.Paragraph>
                {
                  (!item.Product?.KoreanName && (item.Product?.ChineseName || item.Product?.Description3)) &&
                  // item.Product?.nameKorean &&
                  <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ marginLeft: 15, width: 250, margin: 0 }}>{item.Product?.ChineseName || item.Product?.Description3}</Typography.Paragraph>
                }
                {
                  // (item.Product?.KoreanName || item.Product?.ChineseName || item.Product?.Description3) &&
                  // item.PriceKind && (item.PriceKind.indexOf('Special Price') > -1 || item.PriceKind.indexOf('Special Discount') > -1) &&
                  item.PriceKind && (item.PriceKind.indexOf('specialprice') > -1 || item.PriceKind.indexOf('specialdiscount') > -1 || item.PriceKind.indexOf('reductionprice') > -1) &&
                  <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ marginLeft: 15, width: 250, margin: 0 }}>
                    {item.PriceKind.slice(item.PriceKind.indexOf('/') + 1).replace("/", " ")}
                  </Typography.Paragraph>
                }
                {
                  // (item.Product?.KoreanName || item.Product?.ChineseName || item.Product?.Description3) &&
                  item.PriceKind && item.PriceKind.indexOf('Promotion') > -1 &&
                  <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ marginLeft: 15, width: 250, margin: 0 }}>
                    {item.PriceKind.indexOf('Promotion') !== -1 ?
                      item.PriceKind.slice(item.PriceKind.indexOf('Promotion'))
                      :
                      item.PriceKind.slice(item.PriceKind.indexOf('Promotion') + 10)
                    }
                  </Typography.Paragraph>
                }
                {/* {
                  //整单打折时显示原价
                  item.Discount &&
                  <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ marginLeft: 15, width: 200, margin: 0 }}>
                    ORIGINAL PRICE WAS ${item.Price.toFixed(2)} 
                  </Typography.Paragraph>
                } */}
                <Typography.Paragraph
                  ellipsis={{ rows: 1 }}
                  style={{ marginLeft: 15, width: 150, marginBottom: 0 }}
                >
                  {
                    item.Discount ?
                      "@$(" + item.Price.toFixed(2) + " -" + item.Discount.toFixed(0) + "%)" + (item.Price * (item.Discount ? (1 - item.Discount / 100) : 1))?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "")
                      // "@$" + item.UnitCost?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "") + " (-" + item.Discount.toFixed(0) + "%)"
                      :
                      "@$" + item.Price.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "")
                    // "@$" + item.UnitCost?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "")
                  }
                </Typography.Paragraph>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                {
                  (item?.Product?.Description1)
                    ? <Typography.Paragraph ellipsis={{ rows: 1 }} />
                    : null
                }
                <Typography style={{ marginRight: 15, textAlign: "right" }}>${(item.Price * (item.Discount ? (1 - item.Discount / 100) : 1) * item.Qty).toFixed(2)}</Typography>
              </div>
            </div>
          )
        })
      }
      <Typography style={{ fontSize: 16, fontWeight: "bold" }}>You saved ${savedAmount.toFixed(2)}</Typography>
    </div>
  )

  const PaymentInfo = () => (
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignSelf: "center" }}>
      {
        posReceipt?.map((line: string) => {
          return (
            <Typography style={{ alignSelf: "left", whiteSpace: "pre-wrap" }}>{line}</Typography>
          )
        })
      }
      {/* <img src={exampleReceipt} style={{ marginBottom: 10, width: 250, height: 350 }}  /> */}
    </div>
  )

  const TotalInfo = () => (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Typography style={{ marginLeft: 15 }}>{invoice.InvoiceItems.length} SUBTOTAL</Typography>
        <Typography style={{ marginRight: 15 }}>${subTotal.toFixed(2)}</Typography>
        {/* <Typography style={{ marginRight: 15 }}>${invoice.Amount?.toFixed(2)}</Typography> */}
      </div>
      {invoice.OtherCharge ?  //add surcharge if it hava(when total amount is less than $5)
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography style={{ marginLeft: 15 }}>Surcharge</Typography>
          <Typography style={{ marginRight: 15 }}>${invoice.OtherCharge.toFixed(2)}</Typography>
          {/* <Typography style={{ marginRight: 15 }}>${invoice.Amount?.toFixed(2)}</Typography> */}
        </div>
        : null}
      {redeemPoints ?
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography style={{ marginLeft: 15 }}>{redeemPoints} POINTS REDEEM</Typography>
          <Typography style={{ marginRight: 15 }}>-${redeemPoints / 200}</Typography>
          {/* <Typography style={{ marginRight: 15 }}>${invoice.Amount?.toFixed(2)}</Typography> */}
        </div>
        : null}
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Typography style={{ marginLeft: 15, fontWeight: "bold", fontSize: 20 }}>TOTAL</Typography>
        <Typography style={{ marginRight: 15, fontWeight: "bold", fontSize: 20 }}>${invoice.PaidAmount?.toFixed(2)}</Typography>
        {/* <Typography style={{ marginRight: 15, fontWeight: "bold", fontSize: 20 }}>${invoice.Amount?.toFixed(2)}</Typography> */}
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Typography style={{ marginLeft: 15 }}>GST Included in TOTAL</Typography>
        <Typography style={{ marginRight: 15 }}>${invoice.GST?.toFixed(2)}</Typography>
      </div>
      <Divider dashed style={{ borderColor: 'black', marginTop: 10, marginBottom: 10 }} />
      {
        (CardType.indexOf(paymentMethod) > -1 || CardType.indexOf(paymentMethod1) > -1 || CardType.indexOf(paymentMethod2) > -1) ?
          // (paymentMethod === "card" || paymentMethod1 === "card" || paymentMethod2 === "card") ?
          <PaymentInfo />
          :
          null
      }
      {
        recvAcct.length === 1 ?
          // paymentType === "oneOff" ?
          <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography style={{ marginLeft: 15 }}>{paymentMethod?.toUpperCase()}</Typography>
              <Typography style={{ marginRight: 15 }}>${paymentMethod === "CASH" ? (recvAcct[0]?.PaidAmount || 0).toFixed(2) : invoice.PaidAmount?.toFixed(2)}</Typography>
              {/* <Typography style={{ marginRight: 15 }}>${paymentMethod === "cash" ? paymentTotal.depositAmount.toFixed(2) : total.totalAmount?.toFixed(2)}</Typography> */}
            </div>
            {paymentMethod === "CASH" ?
              <>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography style={{ marginLeft: 15 }}>Change</Typography>
                  <Typography style={{ marginRight: 15 }}>${Math.abs(invoice.RecvAccts?.filter(item => item.Notes === 'CHANGE' && item.PaymentID === paymentTotal.PaymentID)[0]?.PaidAmount || 0).toFixed(2)}</Typography>
                  {/* <Typography style={{ marginRight: 15 }}>${paymentMethod === "cash" ? paymentTotal.changeAmount ? paymentTotal.changeAmount.toFixed(2) : Math.abs(invoice.RecvAccts?.filter(item => item.Notes === 'CHANGE')[0]?.PaidAmount || 0).toFixed(2) : "0.00"}</Typography> */}
                </div>
                {invoice.RecvAccts?.some(acct => acct.PayBy === "ROUNDING") ?
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography style={{ marginLeft: 15 }}>Rounding</Typography>
                    <Typography style={{ marginRight: 15 }}>${Math.abs(invoice.RecvAccts?.filter(item => item.PayBy === 'ROUNDING' && item.PaymentID === paymentTotal.PaymentID)[0]?.PaidAmount || 0).toFixed(2)}</Typography>
                  </div>
                  : null
                }
              </>
              : null
            }
          </>
          :
          <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography style={{ marginLeft: 15 }}>Split Payment 1: {paymentMethod1?.toUpperCase()}</Typography>
              <Typography style={{ marginRight: 15 }}>${paymentTotal1?.PaidAmount?.toFixed(2)}</Typography>
            </div>
            {paymentMethod1 === "CASH" ?
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography style={{ marginLeft: 15 }}>Change</Typography>
                <Typography style={{ marginRight: 15 }}>-${Math.abs(invoice.RecvAccts?.filter(item => item.Notes === 'CHANGE' && item.PaymentID === paymentTotal1.PaymentID)[0]?.PaidAmount || 0).toFixed(2)}</Typography>
                {/* <Typography style={{ marginRight: 15 }}>${paymentMethod1 === "cash" ? paymentTotal1.changeAmount?.toFixed(2) : "0.00"}</Typography> */}
              </div>
              : null
            }
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography style={{ marginLeft: 15 }}>Split Payment 2: {paymentMethod2?.toUpperCase()}</Typography>
              <Typography style={{ marginRight: 15 }}>${paymentTotal2?.PaidAmount?.toFixed(2)}</Typography>
            </div>
            {paymentMethod2 === "CASH" ?
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography style={{ marginLeft: 15 }}>Change</Typography>
                <Typography style={{ marginRight: 15 }}>-${Math.abs(invoice.RecvAccts?.filter(item => item.Notes === 'CHANGE' && item.PaymentID === paymentTotal2.PaymentID)[0]?.PaidAmount || 0).toFixed(2)}</Typography>
              </div>
              : null
            }
          </>
      }
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Typography style={{ marginLeft: 15 }}>Points Earned in This Shop</Typography>
        <Typography style={{ marginRight: 15 }}>{invoice.RewardPoints}</Typography>
        {/* <Typography style={{ marginRight: 15 }}>{Math.floor(total.totalAmount * 3)}</Typography> */}
      </div>
    </div>
  )

  const CustomerInfo = () => (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10, marginBottom: 10 }}>
      <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
        {/* <Typography style={{ marginLeft: 15 }}>Date: {moment(invoice && invoice.InvoiceDate).format('MM/DD/YYYY hh:mm:ss')}</Typography> */}
        <Typography style={{ marginLeft: 15 }}>Date: {new Date(`${invoice && invoice.InvoiceDate}`).toLocaleString()}</Typography>
        <Typography style={{ marginLeft: 15 }}>Cashier: {UserName.current}</Typography>
        <Typography style={{ marginLeft: 15 }}>Customer: {invoice.Customer?.CustomerName}[{invoice.Customer?.Mobile ?? invoice.Customer?.Telephone}]</Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <Typography style={{ marginLeft: 15 }}>No: {invoice?.InvoiceNo}</Typography>
        <Typography style={{ marginLeft: 15 }}>Register: {MachineID}</Typography>
        <Typography style={{ marginLeft: 15 }}>Points: {(invoice.CurrentPoints ? invoice.CurrentPoints : 0) + (invoice.RewardPoints ? invoice.RewardPoints : 0) - redeemPoints}</Typography>
      </div>
    </div>
  )

  const BarcodeFooter = () => (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
      {/* <Typography style={{ fontSize: 16, fontWeight: "bold" }}>You saved ${curTotal.discountAmount?.toFixed(2)}</Typography> */}
      <Barcode value={invoice.InvoiceNo} height={80} />
      <Typography>Thank you for shopping with us</Typography>
      <Typography>Print Time:{new Date().toLocaleString()}</Typography>
      <Typography>KT Mart</Typography>
    </div>
  )

  const onPageUp = () => {
    setPageUpDown(pageUpDown - 1)
  }

  const onPageDown = () => {
    setPageUpDown(pageUpDown + 1)
  }

  const onFirstPage = () => {
    let v1 = document.getElementsByClassName("receiptCanvas")[0]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = 0
    }
  }
  const onLastPage = () => {
    let v1 = document.getElementsByClassName("receiptCanvas")[0]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
  }
  const onPageUpDown = (newPage: number) => {
    let v1 = document.getElementsByClassName("receiptCanvas")[0]
    if (!v1) return
    if (oldPage < newPage) {//向下翻页
      v1.scrollTop += height * 0.38
    } else if (oldPage > newPage) {//向上翻页
      v1.scrollTop -= height * 0.38
    }
    setOldPage(newPage)
  };

  useEffect(() => onPageUpDown(pageUpDown), [pageUpDown]);
  // const ReceiptMainContent = () => (
  //   <div style={{ display: "flex", flexDirection: "column" }} id="canvas" ref={componentRef}>
  //     <CompanyInfo />
  //     <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
  //     <InvoiceItems />
  //     <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
  //     <TotalInfo />
  //     <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
  //     <CustomerInfo />
  //     <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
  //     <BarcodeFooter />
  //   </div>
  // )

  return (
    <Modal
      // title={
      //   <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
      //     <Button
      //       type="primary"
      //       style={{ fontSize: 24, height: 50, width: 200, marginRight: 50 }}
      //       onClick={() => {
      //         handlePrint()
      //         clearCartTrigger()
      //       }}
      //     >
      //       Print Receipt
      //     </Button>
      //     <Button
      //       type="primary"
      //       style={{ fontSize: 24, height: 50, width: 200, backgroundColor: "green" }}
      //       onClick={() => {
      //         // handlePrint()
      //         clearCartTrigger()
      //         onCancel()
      //         navigate("/checkout")
      //       }}
      //     >
      //       Next Transaction
      //     </Button>
      //   </div>
      // }
      visible={isVisible}
      footer={null}
      width={width * 0.7}
      zIndex={1000}
      closable={false}
      onCancel={onCancel}
      className="modalPage"
      transitionName="none"
      maskTransitionName="none"
      title={<div className="modalTitle">Sales Receipt<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
    >
      {isInitPrinterAlertModal ?
        <ActionConfirmModal
          isVisible={isInitPrinterAlertModal}
          description={"Printer Error. Please Restart The Printer, Then Click 'Reset'"}
          actionText={"Reset"}
          cancelText={"Cancel"}
          onConfirm={() => {
            //@ts-ignore
            if (window?.electronAPI) {
              console.log("打印机重新初始化...", false)
              //@ts-ignore
              window.electronAPI.initPrinter(PosConfig.printerInterface, (response) => {
                console.log('重置打印机结果: ', response);
                if (response === 'failed') {
                  message.error("Printer init failed.")
                } else {
                  handlePrint()
                }
              })
            }
            setIsInitPrinterAlertModal(false)
          }}
          onCancel={() => {
            setIsInitPrinterAlertModal(false)
          }}
          rightButtonDanger={false}
        />
        : null
      }
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "-40px" }}>
        <div style={{ width: "400px" }}>
          <div style={{ flexDirection: "column", display: "flex", height: "100%" }}>
            {
              (recvAcct.length === 1 && paymentMethod === "CASH") ?
                // (paymentType === "oneOff" && paymentMethod === "cash") ?
                <div style={{ marginRight: 15, marginBottom: 30 }}>
                  <PaymentTotalReceiptSection
                    containerContent={{ totalAmount: invoice.PaidAmount, recvAccts: invoice?.RecvAccts }}
                  />
                </div>
                :
                null
            }
            <button
              className="modalPrintButton"
              onClick={() => {
                handlePrint()
                // clearCartTrigger()
              }}
            >
              Print Receipt
            </button>
            <button
              className="modalNextTransButton"
              onClick={() => {
                // handlePrint()
                clearCartTrigger()
                onCancel()
                navigate("/checkout")
              }}
            >
              Next Transaction
            </button>
          </div>
        </div>
        {
          invoice ?
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="receiptCanvas" id="canvasCover">
                <div style={{ display: "flex", flexDirection: "column", width: 400, paddingBottom: 10 }} id="canvas" ref={componentRef}>
                  <CompanyInfo />
                  <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
                  <InvoiceItems />
                  <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
                  <TotalInfo />
                  <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
                  <CustomerInfo />
                  <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
                  <BarcodeFooter />
                  <p style={{ color: '#000' }}>{'.'}</p>
                </div>
              </div>
              <ScrollButtons
                onPageDown={onLastPage}
                onPageUp={onFirstPage}
                onRowDown={onPageDown}
                onRowUp={onPageUp}
              />
            </div>
            :
            null
        }
      </div>
    </Modal>
  )
}