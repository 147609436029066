import {
  Button,
  Descriptions,
  Divider,
  PageHeader,
  Popconfirm,
  Spin,
  Typography,
  Image,
  Table,
  Modal
} from "antd"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { InvoiceHead, InvoiceItem } from "../../models"
import useViewport from "../../hooks/useViewport"
import html2canvas from "html2canvas"
import Barcode from "react-barcode"
import { loadAllInvoices, loadCustomers, serveCustomer, updateCustomer } from "../../redux/Customer/slice"
import { clearCart, clearRefundCart, setRefundMode } from "../../redux/Checkout/slice"
import { reloadCustomerScreen } from "../../utils/customerscreen-functions"
import { useNavigate } from "react-router-dom"
import { listCustomers, getCustomerByCustomerCode } from "../../api/customer"
import { listInvoices, listNoneRefundInvoices } from "../../api/invoice"
import { customerInDBToCustomer, invoiceInDBsToLocalInvoices } from "../../utils/Global"
import { clearPaymentProcedure } from "../../redux/Payment/slice"
// import { ExamplePOSReceipt } from "../../constants/data"
import { getConfiguration } from "../../utils/configurate"
import { generateTableCustomKorean } from "../../utils/customTableKorean"
import { PostDrawOpen } from "../../api/drawOpen"
import ScrollButtons from "../common/ScrollButtons"

const { Title } = Typography
const logo = require("../../assets/png/logo.png")
const exampleReceipt = require("../../assets/png/pos-example.png")

type Props = {
  isVisible: boolean
  invoice: InvoiceHead
  // paymentMethod?: "cash" | "card" | "royalpay"
  setVisibility?: (value: boolean) => void
  showPaymentTotal?: boolean
  onCancel: () => void
  isSale?: boolean
}

export default function RefundReceiptModal({ invoice, isVisible, setVisibility, onCancel, isSale = true,
  // paymentMethod, 
  showPaymentTotal }: Props) {
  const PosConfig = getConfiguration("ReceiptModal")
  const { machineID: MachineID, warehouseName, warehouseAddress: Address, warehousePhone: Telephone, serverAddress: ServerAddress, port: Port, abn: ABN } = PosConfig
  const CardType = "MASTERCARD,VISA,DEBITCARD,EFTPOS"
  const recvAcct = invoice?.RecvAccts?.filter(acct => !acct.Notes && acct.PayBy !== "ROUNDING")
  const paymentMethod = recvAcct?.[0] ? recvAcct[0].PayBy.replaceAll(" ", "") : null
  console.log("paymentMethod", paymentMethod, recvAcct?.[0] ? recvAcct[0]: 'null')
  console.log("退货发票信息", invoice)
  const paymentMethod1 = recvAcct?.[0] ? recvAcct[0].PayBy.replaceAll(" ", "") : null
  const paymentMethod2 = recvAcct?.[1] ? recvAcct[1].PayBy.replaceAll(" ", "") : undefined
  const paymentTotal = recvAcct?.[0]?  recvAcct[0] : undefined
  const paymentTotal1 = recvAcct?.[0]? recvAcct[0] : undefined
  const paymentTotal2 = recvAcct?.[1] ? recvAcct[1] : undefined
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width, height } = useViewport()
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total } = Checkout
  // const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  // const { paymentTotal, paymentTotal1, paymentTotal2, paymentType, paymentStep, paymentMethod1, paymentMethod2, paymentAmount1, paymentAmount2 } = Payment
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer } = Customer
  const UserName = useRef(invoice && invoice?.Operator ? invoice?.Operator.Name : 'Supervisor')
  const [subTotal, setSubTotal] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [canvasUrl, setCanvasUrl] = useState<any>()
  const [posReceipt, setPosReceipt] = useState(invoice?.ReceiptCustomerCopy?.split(","))
  // const [posReceipt, setPosReceipt] = useState(Payment.customerCopy)
  const componentRef = useRef(null)
  const [pageUpDown, setPageUpDown] = useState<number>(1)
  const [oldPage, setOldPage] = useState(1)
  const printerWidth = 42 // Default width of the printer is set to 48
  const emptyChars = '                                          '//长度为42的空字符串
  const [curTotal, setCurTotal] = useState<any>(total)
  const [rewardPoints, setRewardPoints] = useState<number>(0)
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // })

  useEffect(() => {
    if ((paymentMethod === 'CASH' || paymentMethod1 === 'CASH' || paymentMethod2 === 'CASH') && isSale) {
      // if (paymentMethod === 'cash' || paymentMethod1 === 'cash' || paymentMethod2 === 'cash') {
      //@ts-ignore
      if (window?.electronAPI) {
        //@ts-ignore
        console.log('openDrawComponent', window?.electronAPI?.openDrawComponent)
        //@ts-ignore
        window?.electronAPI?.openDrawComponent((response) => {
          console.log('Todo add sale open draw log: ', response);
        });
      }
      const openRecord: any = {
        Reasons: `InvoiceNo: ${invoice?.InvoiceNo}`
      };
      PostDrawOpen(openRecord)
    }
  }, [])
  useEffect(() => {
    dispatch(clearCart({}))
    dispatch(serveCustomer({ customer: null }))
    //汇总产品金额得到subtotal
    if (invoice?.InvoiceItems) {
      const itemAmountArray = invoice?.InvoiceItems.map(item => Number((item.Price * (item.Discount ? (1 - item.Discount / 100) : 1) * item.Qty).toFixed(2)))
      setSubTotal(itemAmountArray.reduce(function (prev, curr, idx, arr) {
        return prev + curr;
      }))
      console.log('汇总产品金额得到subtotal', invoice?.InvoiceItems, itemAmountArray)
    }
    //解析rewardpoint
    if (invoice && invoice?.LoyaltyPoints && invoice?.LoyaltyPoints.length > 0) {
      invoice?.LoyaltyPoints.forEach(element => {
        if (element.RewardPoints !== 0) {
          setRewardPoints(element.RewardPoints)
        }
      })
    }
  }, [])


  const clearCartTrigger = () => {
    const setClearCartPromise = () => new Promise(async (resolve, reject) => {
      dispatch(serveCustomer({ customer: null }))
      dispatch(clearRefundCart({}))
      dispatch(setRefundMode({ isRefundMode: false }))
      const invoicesData = await listNoneRefundInvoices()
      const invoices = invoiceInDBsToLocalInvoices(invoicesData.data)
      dispatch(loadAllInvoices({ invoices: invoices }))
      resolve("finish")
    })
    setClearCartPromise()
      .then(() => {
        // navigate("/checkout")
        // reloadCustomerScreen()
      })
      .catch((e) => console.log(e))
  }
  const handlePrint = () => {
    console.log('forwarding print request to the main process...');
    const headArray: any[] = []
    //生成头部数据
    let headSting: any = []
    headSting.push({
      text: `${warehouseName}(${Address})`,
      align: 'CENTER',
      width: 1,
      style: 'B'
    })
    headArray.push({ headSting })
    headSting = []
    headSting.push({
      text: `PHONE: ${Telephone}`,
      align: 'CENTER',
      width: 1,
      style: 'B'
    })
    headArray.push({ headSting })
    headSting = []
    headSting.push({
      text: `TAX INVOICE - ABN: ${ABN ? ABN : 85613150691}`,
      align: 'CENTER',
      width: 1,
      style: 'B'
    })
    headArray.push({ headSting })
    headSting = []

    const itemArray: any[] = []
    //生成明细数据
    invoice?.InvoiceItems.forEach((item: any) => {
      let itemSting: any = []

      if (item?.Product?.Description1) {//特价名称
        itemArray.push({ itemSting: [{ text: item.Product.Description1, align: 'LEFT', width: 1, encoding: '949' }] })
      }

      // generateTableCustomKorean(itemArray, item)
      let productName = ''
      // Update: move korean to front
      if (item.Product?.KoreanName) {
        productName += item.Product?.KoreanName + ' '
      }
      productName = item.Product?.Description
      let encoding = 'EUC-KR'//"949"
      if (!item.Product?.KoreanName && item.Product?.ChineseName) {
        productName += item.Product?.ChineseName
        encoding = 'GB18030'
        // } else if (item.Product?.Description3) {
        //   productName += item.Product?.Description3
        //   encoding = 'EUC-KR'
        //   // encoding = 'EUC-JP'
      }
      const lengthInBytes = new Blob([productName]).size
      const empLength = Math.max(printerWidth - productName.length - (lengthInBytes - productName.length) / 2, 0)
      itemSting.push({//产品名称
        text: `${productName}${emptyChars.substring(0, empLength)}`,
        align: 'LEFT',
        width: 1,
        encoding
        // style: 'B'
      })

      itemArray.push({ itemSting, encoding: '949' })
      itemSting = []// || item.Product?.ChineseName || item.Product?.Description3

      if (item.PriceKind && item.PriceKind.indexOf('Special Price') > -1) {
        itemSting.push({
          text: `${item.PriceKind.slice(13).replace("/", " ")}`,
          align: 'LEFT',
          width: 1,
          // style: 'B'
        })

        itemArray.push({ itemSting })
        itemSting = []
      }
      if (item.PriceKind && item.PriceKind.indexOf('Promotion') > -1) {
        itemSting.push({
          text: `${item.PriceKind.indexOf('promotion/') !== -1 ?
            item.PriceKind.slice(item.PriceKind.indexOf('Promotion'))
            :
            item.PriceKind.slice(item.PriceKind.indexOf('Promotion') + 10)
            }`,
          align: 'LEFT',
          width: 1,
          // style: 'B'
        })

        itemArray.push({ itemSting })
        itemSting = []
      }
      //处理单价数量和总价行
      let priceTextLine = item.Discount ?
        "@$" + item.Price?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "") + " (-" + item.Discount.toFixed(0) + "%)"
        // "@$" + item.UnitCost?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "") + " (-" + item.Discount.toFixed(0) + "%)"
        :
        "@$" + item.Price?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "")
      // "@$" + item.UnitCost?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "")
      let leftLength = printerWidth - priceTextLine.length

      if (leftLength > 0 && item.Price?.toFixed(2).length < leftLength) {
        priceTextLine += emptyChars.substring(0, leftLength - (item.Price?.toFixed(2).length + 1)) + "$" + item.Price?.toFixed(2)
      } else {
        priceTextLine += '\CR'
        priceTextLine += emptyChars.substring(0, printerWidth - (item.Price?.toFixed(2).length + 1)) + "$" + item.Price?.toFixed(2)
      }
      itemSting.push({
        text: priceTextLine,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })

      itemArray.push({ itemSting })
      itemArray.push({
        itemSting: [{//插入空行
          text: ` `,
          width: 0.7,
        }]
      })
    })

    //生成汇总数据
    const subTotalArray: any[] = []
    let subTotalSting: any = []
    let subTotallineText = `${invoice?.InvoiceItems.length} SUBTOTAL`
    subTotallineText += emptyChars.substring(0, printerWidth - subTotallineText.length - subTotal.toFixed(2).length - 1) + `$${subTotal.toFixed(2)}`
    subTotalSting.push({
      text: subTotallineText,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })

    subTotalArray.push({ subTotalSting })
    subTotalSting = []

    subTotallineText = `TOTAL`
    subTotallineText += emptyChars.substring(0, printerWidth - subTotallineText.length - (invoice?.PaidAmount ? invoice?.PaidAmount.toFixed(2).length : 3) - 1) + `$${invoice?.PaidAmount?.toFixed(2)}`
    // subTotallineText += emptyChars.substring(0, printerWidth - subTotallineText.length - (invoice?.Amount ? invoice?.Amount.toFixed(2).length : 3) - 1) + `$${invoice?.Amount?.toFixed(2)}`

    subTotalSting.push({
      text: subTotallineText,
      // text: `TOTAL`,
      align: 'LEFT',
      width: 1,
      style: 'B'
    })

    subTotalArray.push({ subTotalSting })
    subTotalSting = []
    subTotallineText = `GST Included in TOTAL`
    subTotallineText += emptyChars.substring(0, printerWidth - subTotallineText.length - (invoice?.GST ? invoice?.GST.toFixed(2).length : 4) - 1) + `$${invoice?.GST ? invoice?.GST.toFixed(2) : "0.00"}`

    subTotalSting.push({
      text: subTotallineText,
      // text: `GST Included in TOTAL`,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })

    subTotalArray.push({ subTotalSting })
    // subTotalSting = []
    //支付部分
    const payArray: any[] = []
    let paySting: any = []
    //如果有刷卡，显示刷卡信息
    if (CardType.indexOf(paymentMethod!) > -1 || CardType.indexOf(paymentMethod1!) > -1 || CardType.indexOf(paymentMethod2!) > -1) {
      // if (paymentMethod === "card" || paymentMethod1 === "card" || paymentMethod2 === "card") {
      posReceipt?.forEach((line: string) => {
        paySting.push({
          text: line,
          align: 'CENTER',
          width: 1,
          // style: 'B'
        })
        payArray.push({ paySting })
        paySting = []
      })

    }
    //显示支付方式和金额
    let paylineText = ""
    let payValue = ""
    if (recvAcct?.length === 1) {
      paylineText = `${paymentMethod?.toUpperCase()}`
      payValue = `$${paymentMethod === "CASH" ? (recvAcct[0]?.PaidAmount || 0).toFixed(2) : invoice?.PaidAmount?.toFixed(2)}`
      paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue

      paySting.push({
        text: paylineText,
        // text: `${paymentMethod?.toUpperCase()}`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })

      payArray.push({ paySting })
      paySting = []
      if (paymentMethod === "CASH") {
        paylineText = `Change`
        payValue = `$ -${Math.abs(invoice?.RecvAccts?.filter(item => item.Notes === 'CHANGE')[0]?.PaidAmount || 0).toFixed(2)}`
        paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
        paySting.push({
          text: paylineText,
          // text: `Change`,
          align: 'LEFT',
          width: 1,
          // style: 'B'
        })

        payArray.push({ paySting })
        paySting = []
        if (invoice?.RecvAccts?.some(acct => acct.PayBy === "ROUNDING")) {
          paylineText = `Rounding`
          payValue = `$${Math.abs(invoice?.RecvAccts?.filter(item => item.PayBy === 'ROUNDING' && item.PaymentID === paymentTotal?.PaymentID)[0]?.PaidAmount || 0).toFixed(2)}`
          paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
          paySting.push({
            text: paylineText,
            // text: `Rounding`,
            align: 'LEFT',
            width: 1,
            // style: 'B'
          })

          payArray.push({ paySting })
          paySting = []
        }
      }
    } else {
      paylineText = `Split Payment 1: ${paymentMethod1?.toUpperCase()}`
      payValue = `$ ${paymentTotal1?.PaidAmount.toFixed(2)}`
      paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
      paySting.push({
        text: paylineText,
        // text: `Split Payment 1: ${paymentMethod1?.toUpperCase()}`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })

      payArray.push({ paySting })
      paySting = []
      if (paymentMethod1 === "CASH") {
        paylineText = `Change`
        payValue = `$ -${Math.abs(invoice?.RecvAccts?.filter(item => item.Notes === 'CHANGE' && item.PaymentID === paymentTotal1?.PaymentID)[0]?.PaidAmount || 0).toFixed(2)}`
        paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
        paySting.push({
          text: paylineText,
          // text: `Change`,
          align: 'LEFT',
          width: 1,
          // style: 'B'
        })

        payArray.push({ paySting })
        paySting = []
      }
      paylineText = `Split Payment 2: ${paymentMethod2?.toUpperCase()}`
      payValue = `$ ${paymentTotal2?.PaidAmount.toFixed(2)}`
      paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
      paySting.push({
        text: paylineText,
        // text: `Split Payment 2: ${paymentMethod2?.toUpperCase()}`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })

      payArray.push({ paySting })
      paySting = []
      if (paymentMethod2 === "CASH") {
        paylineText = `Change`
        payValue = `$ ${Math.abs(invoice?.RecvAccts?.filter(item => item.Notes === 'CHANGE' && item.PaymentID === paymentTotal2?.PaymentID)[0]?.PaidAmount || 0).toFixed(2)}`
        paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
        paySting.push({
          text: paylineText,
          // text: `Change`,
          align: 'LEFT',
          width: 1,
          // style: 'B'
        })

        payArray.push({ paySting })
        paySting = []
      }
    }
    if (invoice?.Customer && invoice?.RewardPoints) {
      paylineText = `Points Earned in This Shop`
      payValue = `${invoice?.RewardPoints}`
      paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue
      paySting.push({
        text: paylineText,
        // text: `Points Earned in This Shop`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })

      payArray.push({ paySting })
    }
    paylineText = `Points Earned in This Shop`
    payValue = `${rewardPoints.toFixed(2)}`
    paylineText += emptyChars.substring(0, printerWidth - paylineText.length - payValue.length) + payValue

    paySting.push({
      text: paylineText,
      // text: `${paymentMethod?.toUpperCase()}`,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })

    payArray.push({ paySting })
    paySting = []

    //客户部分
    const custArray: any[] = []
    let custSting: any = []
    let custlineText = ""
    let custValue = ""
    custlineText = `Date: ${new Date().toLocaleDateString()}`
    custValue = `No: ${invoice?.InvoiceNo}`
    custlineText += emptyChars.substring(0, printerWidth - custlineText.length - custValue.length) + custValue
    custSting.push({
      text: custlineText,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })

    custArray.push({ custSting })
    custSting = []

    custlineText = `Cashier: ${UserName.current}`
    custValue = `Register: ${MachineID}`
    custlineText += emptyChars.substring(0, printerWidth - custlineText.length - custValue.length) + custValue
    custSting.push({
      text: custlineText,
      // text: `Cashier: Supervisor`,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })

    custArray.push({ custSting, encoding: '949' })
    custSting = []
    custlineText = `Customer: ${invoice?.Customer ? `${invoice?.Customer.CustomerName}[${invoice?.Customer?.Mobile ?? invoice?.Customer?.Telephone ?? invoice?.Customer?.CustomerCode ?? ''}]` : 'Cash Customer'}`
    // custValue = `Points: ${(invoice?.CurrentPoints? invoice?.CurrentPoints : 0) + (invoice?.RewardPoints? invoice?.RewardPoints : 0) - redeemPoints}`
    // custValue = `Points: ${invoice?.Customer && invoice?.RewardPoints ? invoice?.RewardPoints : 0}`
    custlineText += emptyChars.substring(0, printerWidth - custlineText.length)
    // custlineText += emptyChars.substring(0, printerWidth - custlineText.length - custValue.length) + custValue

    custSting.push({
      text: custlineText,
      // text: `Customer: ${invoice?.Customer ? invoice?.Customer.CustomerName : 'Cash Customer'}`,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })

    custArray.push({ custSting, encoding: '949' })
    custSting = []
    custlineText = `Points: ${(invoice?.CurrentPoints ? invoice?.CurrentPoints : 0) + (rewardPoints ?? 0)}`
    custlineText += emptyChars.substring(0, printerWidth - custlineText.length)
    custSting.push({
      text: custlineText,
      align: 'LEFT',
      width: 1,
      // style: 'B'
    })

    custArray.push({ custSting, encoding: '949' })
    custSting = []
    // custlineText = `Customer: ${invoice?.Customer ? invoice?.Customer.CustomerName : 'Cash Customer'}`
    // custValue = `Points: ${invoice?.Customer && invoice?.RewardPoints ? invoice?.RewardPoints : 0}`
    // custlineText += emptyChars.substring(0, printerWidth - custlineText.length - custValue.length) + custValue

    // custSting.push({
    //   text: custlineText,
    //   // text: `Customer: ${invoice?.Customer ? invoice?.Customer.CustomerName : 'Cash Customer'}`,
    //   align: 'LEFT',
    //   width: 1,
    //   // style: 'B'
    // })

    // custArray.push({ custSting, encoding: '949' })
    // custSting = []

    if (curTotal.discountAmount && curTotal.discountAmount > 0) {
      custlineText = `You saved ${curTotal.discountAmount?.toFixed(2)}`
      custValue = ``
      custlineText += emptyChars.substring(0, printerWidth - custlineText.length - custValue.length) + custValue

      custSting.push({
        text: custlineText,
        // text: `You saved ${curTotal.discountAmount?.toFixed(2)}`,
        align: 'LEFT',
        width: 1,
        // style: 'B'
      })
      custArray.push({ custSting })
      custSting = []
    }


    const printJson = {
      headArray,
      itemArray,
      subTotalArray,
      payArray,
      custArray,

      invoiceNo: invoice?.InvoiceNo,
    }

    console.log('printJson', printJson)


    //@ts-ignore
    window.electronAPI.printComponentV2(printJson, (response) => {
      // window.electronAPI.printComponent(url, (response) => {
      console.log('Main: ', response);
    });
    // console.log('Main: ', data);
    // });
  };

  const printTrigger = () => {
    return <button style={{ marginLeft: '1rem' }}>Print </button>;
  };

  const previewTrigger = () => {
    return <button style={{ marginLeft: '1rem' }}>Preview </button>;
  };

  const capture = () => {
    //@ts-ignore
    html2canvas(document.getElementById("canvas")).then(function (canvas) {
      return canvas.toDataURL()
      // console.log("canvas: ", canvas.toDataURL())
      // setCanvasUrl(canvas.toDataURL())
      // document.body.appendChild(canvas)
    })
  }

  const CompanyInfo = () => (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <img src={process.env.PUBLIC_URL + "/images/logo.png"} style={{ marginBottom: 10, width: 120, height: 120 }} />
      <Typography style={{ fontSize: 16, fontWeight: "bold" }}>600 Elizabeth Street Melbourne VIC 3000</Typography>
      <Typography style={{ fontSize: 16, fontWeight: "bold" }}>PHONE: 0478 801 827</Typography>
      <Typography style={{ fontSize: 16, fontWeight: "bold" }}>TAX INVOICE - ABN: 0000 0000 0000</Typography>
    </div>
  )

  const InvoiceItems = () => (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      {
        invoice?.InvoiceItems.map((item: any) => {
          return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 10 }}>
              <div>
                {
                  (item.Product?.KoreanName) &&
                  <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ marginLeft: 15, width: 200, margin: 0 }}>{item.Product?.KoreanName || item.Product?.ChineseName || item.Product?.Description3}</Typography.Paragraph>
                }
                <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ marginLeft: 15, width: 200, margin: 0 }}>{item.Product?.Description}</Typography.Paragraph>
                {
                  (!item.Product?.KoreanName && (item.Product?.ChineseName || item.Product?.Description3)) &&
                  <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ marginLeft: 15, width: 200, margin: 0 }}>{item.Product?.ChineseName || item.Product?.Description3}</Typography.Paragraph>
                }
                <Typography.Paragraph
                  ellipsis={{ rows: 1 }}
                  style={{ marginLeft: 15, width: 200, marginBottom: 0 }}
                >
                  {
                    item.Discount ?
                    "@$(" + item.Price.toFixed(2) + " -" + item.Discount.toFixed(0) + "%)"+ (item.Price * (item.Discount ? (1 - item.Discount / 100) : 1))?.toFixed(2) + (item.Product?.Scalable ? "/kg" : "") + " x " + item.Qty + (item.Product?.Scalable ? "kg" : "") 
                    // "@$" + item.Price?.toFixed(2) + " x " + item.Qty + " (-" + item.Discount.toFixed(0) + "%)"
                      :
                      "@$" + item.Price?.toFixed(2) + " x " + item.Qty
                    // "@$" + item.UnitCost?.toFixed(2) + " x " + item.Qty + " (-" + item.Discount.toFixed(0) + "%)"
                    // :
                    // "@$" + item.UnitCost?.toFixed(2) + " x " + item.Qty
                  }
                </Typography.Paragraph>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ marginRight: 15, textAlign: "right" }}>-${(item.Price * (item.Discount ? (1 - item.Discount / 100) : 1) * item.Qty).toFixed(2)}</Typography>
                {/* <Typography style={{ marginRight: 15, textAlign: "right" }}>-${item.Price?.toFixed(2)}</Typography> */}
              </div>
            </div>
          )
        })
      }
    </div>
  )

  const PaymentInfo = () => (
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignSelf: "center" }}>
      {
        posReceipt?.map((line: string) => {
          console.log("line........", line)
          return (
            <Typography style={{ alignSelf: "center" }}>{line}</Typography>
          )
        })
      }
    </div>
  )

  const TotalInfo = () => (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Typography style={{ marginLeft: 15 }}>{invoice?.InvoiceItems.length} SUBTOTAL</Typography>
        <Typography style={{ marginRight: 15 }}>-${Math.abs(subTotal!).toFixed(2)}</Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Typography style={{ marginLeft: 15, fontWeight: "bold", fontSize: 20 }}>TOTAL</Typography>
        <Typography style={{ marginRight: 15, fontWeight: "bold", fontSize: 20 }}>-${Math.abs(invoice?.PaidAmount!).toFixed(2)}</Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Typography style={{ marginLeft: 15 }}>GST Included in TOTAL</Typography>
        <Typography style={{ marginRight: 15 }}>${invoice?.GST ? invoice?.GST?.toFixed(2) : "0.00"}</Typography>
      </div>
      <Divider dashed style={{ borderColor: 'black', marginTop: 10, marginBottom: 10 }} />
      {
        (paymentMethod === "card" || paymentMethod1 === "card" || paymentMethod2 === "card") ?
          <PaymentInfo />
          :
          null
      }
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Typography style={{ marginLeft: 15 }}>Points Earned in This Shop</Typography>
        <Typography style={{ marginRight: 15 }}>{rewardPoints.toFixed(2)}</Typography>
        {/* <Typography style={{ marginRight: 15 }}>{Math.floor(total.totalAmount * 3)}</Typography> */}
      </div>
    </div>
  )

  const CustomerInfo = () => (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10, marginBottom: 10 }}>
      <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <Typography style={{ marginLeft: 15 }}>Date: {new Date().toLocaleDateString()}</Typography>
        <Typography style={{ marginLeft: 15 }}>Cashier: {UserName.current}</Typography>
        <Typography style={{ marginLeft: 15 }}>Customer: {invoice?.Customer?.CustomerName}[{invoice?.Customer?.Mobile ?? invoice?.Customer?.Telephone}]</Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <Typography style={{ marginLeft: 15 }}>No: {invoice?.InvoiceNo}</Typography>
        <Typography style={{ marginLeft: 15 }}>Register: {MachineID}</Typography>
        <Typography style={{ marginLeft: 15 }}>Points: {(invoice?.CurrentPoints ? invoice?.CurrentPoints : 0) + (rewardPoints ?? 0)}</Typography>
      </div>
    </div>
  )

  const BarcodeFooter = () => (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
      <Barcode value={invoice?.InvoiceNo} height={80} />
      <Typography>Thank you for shopping with us</Typography>
      <Typography>{new Date().toLocaleString()}</Typography>
      <Typography>KT Mart</Typography>
    </div>
  )
  const onPageUp = () => {
    setPageUpDown(pageUpDown - 1)
  }

  const onPageDown = () => {
    setPageUpDown(pageUpDown + 1)
  }

  const onFirstPage = () => {
    let v1 = document.getElementsByClassName("receiptCanvas")[0]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = 0
    }
  }
  const onLastPage = () => {
    let v1 = document.getElementsByClassName("receiptCanvas")[0]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
  }
  const onPageUpDown = (newPage: number) => {
    let v1 = document.getElementsByClassName("receiptCanvas")[0]
    if (!v1) return
    if (oldPage < newPage) {//向下翻页
      v1.scrollTop += height * 0.38
    } else if (oldPage > newPage) {//向上翻页
      v1.scrollTop -= height * 0.38
    }
    setOldPage(newPage)
  };

  useEffect(() => onPageUpDown(pageUpDown), [pageUpDown]);

  return (
    <Modal
      transitionName="none"
      maskTransitionName="none"
      title={<div className="modalTitle">Refund Receipt<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
      visible={isVisible}
      footer={null}
      onCancel={()=>{
        // clearCartTrigger()
        onCancel && onCancel()
        // navigate("/checkout")
      }}
      width={width * 0.7}
      closable={false}
      zIndex={1000}
      className="modalPage"
    >
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "-40px" }}>
        <div style={{ width: "490px" }}>
          <div style={{ flexDirection: "column", display: "flex", height: "100%" }}>
            <button
              className="modalPrintButton"
              onClick={() => {
                handlePrint()
                // clearCartTrigger()
              }}
            >
              Print Receipt
            </button>
            <button
              className="modalNextTransButton"
              onClick={() => {
                // handlePrint()
                clearCartTrigger()
                onCancel()
                navigate("/checkout")
              }}
            >
              Next Transaction
            </button>
          </div>
        </div>
        {
          invoice ?
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="receiptCanvas" id="canvasCover">
                <div style={{ display: "flex", flexDirection: "column", width: 400, paddingBottom: 10 }} id="canvas" ref={componentRef}>
                  <CompanyInfo />
                  <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
                  <InvoiceItems />
                  <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
                  <TotalInfo />
                  <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
                  <CustomerInfo />
                  <Divider dashed style={{ borderColor: 'black', margin: 0 }} />
                  <BarcodeFooter />
                  <p style={{ color: '#000' }}>{'.'}</p>
                </div>
              </div>
              <ScrollButtons
                onPageDown={onLastPage}
                onPageUp={onFirstPage}
                onRowDown={onPageDown}
                onRowUp={onPageUp}
              />
            </div>
            :
            null
        }
      </div>
    </Modal>
  )
}
