import React, { useEffect, useState } from "react"
import { StyleSheet, Product, OrderItem } from "../../models"
import { Image, Typography, Card, Button, InputNumber, Tag } from "antd"
import { StarOutlined, RightOutlined } from "@ant-design/icons"
import InputNumberButtonGroup from "../common/InputNumberButtonGroup"
import { getProductDisplayName } from "../../utils/getProductDisplayName"
import { nowIsWithinTimePeriod } from "../../utils/Global"

interface CheckoutProductCardProp {
  product: Product
  cardWidth?: number
  cardHeight?: number
  imageWidth?: number
  imageHeight?: number
  showAddToCart?: boolean
  onCardPress?: (product: Product) => void
  onViewDetail?: (product: Product) => void
  onAddToCart?: (product: Product) => void
  gridStyle?: React.CSSProperties
}

const CheckoutProductCard: React.FC<CheckoutProductCardProp> = ({
  product,
  cardHeight = 128,
  cardWidth = 125,
  imageWidth = 80,
  showAddToCart = true,
  onCardPress,
  onViewDetail,
  onAddToCart,
  gridStyle
}) => {
  const [fontStyle, setFontStyle] = useState({})

  const calculatePrice = (item: Product) => {
    const onlinePrice = item.price? item.price : 0
    const onlineSpecials = item.specialPrices || []
    let specialPrice = onlinePrice
    let isSpecial: boolean = false
    if (onlineSpecials.length > 0) {
      onlineSpecials.forEach((price: any) => {
        // if (onlineSpecials.length > 0) {
        //   onlineSpecials.forEach((price: any) => {
        // console.log('当前特价', price)
        // if (price.Live && nowIsWithinTimePeriod(price.StartDate, price.EndDate)) {
        //添加会员特价过滤判断
        if (
          !price.OnlyForMember &&
          price.Live && (nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm === 1)) {
          isSpecial = true
          switch (price.Type) {
            case "SpecialPrice":
              specialPrice = price.SpecialPrice
              break
            case "ReductionPrice":
              specialPrice = specialPrice - price.ReductionPrice
              break
            case "SpecialDiscount":
              specialPrice = specialPrice * (1 - price.SpecialDiscount / 100)
              break
          }
        }
      })
    }
    const unitPrice = item.includeGST && item.gstRate ? Number((specialPrice * (100 + item.gstRate) / 100)?.toFixed(2)) : specialPrice
    console.log('显示价格问题', unitPrice)
    return { unitPrice, isSpecial }
  }
  const { unitPrice, isSpecial } = calculatePrice(product)

  useEffect(() => {
    if (product.isFontBold) {
      setFontStyle(
        { ...fontStyle, fontWeight: "bold" }
      )
    }
    if (product.fontSize) {
      setFontStyle(
        { ...fontStyle, fontSize: Math.max(product.fontSize, 12) }
      )
    }
    if (product.isFontItalic) {
      setFontStyle(
        { ...fontStyle, fontStyle: "italic", }
      )
    }
    if (product.isFontUnderline) {
      setFontStyle(
        { ...fontStyle, textDecoration: "underline", }
      )
    }
    if (product.isFontStrikeout) {
      setFontStyle(
        { ...fontStyle, textDecoration: "line-through", }
      )
    }
  }, [])

  return (
    <Card
      onClick={(e) => {
        onCardPress && onCardPress(product)
      }}
      style={{
        ...gridStyle,
        width: '100%',
        minWidth: cardWidth,
        height: cardHeight,
        // backgroundColor: "red"
      }}
      bodyStyle={{ padding: 0 }}
      hoverable
    >

      <div
        style={{ margin: 0, display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {product.isShowImage ?
          <div style={{ display: "flex", alignItems: "center" }}>
            <Image
              src={product.image}
              width={imageWidth}
              height={imageWidth}
              preview={false}
              style={{ alignSelf: "center", border: "1px solid #979797", borderRadius: "10px" }}
            />
            <Tag
              color={isSpecial ? "error" : "success"}
              style={{ position: "absolute", left: 0, top: 0, fontWeight: 700 }}
            >${unitPrice?.toFixed(2)}</Tag>
            <Tag
              // color={"success"}
              style={{ position: "absolute", left: 0, top: 60, fontWeight: 700 }}
            >{product.productCode}</Tag>
          </div>
          : <>
            <Tag
              color={isSpecial ? "error" : "success"}
              style={{ fontWeight: 700 }}
            >${unitPrice?.toFixed(2)}</Tag>
            <Tag
              // color={"success"}
              style={{ fontWeight: 700 }}
            >{product.productCode}</Tag>
          </>
        }
        <div className={product.isShowImage ? "cardProductName" : "cardProductNameNoImage"}
          style={{ ...fontStyle }}
        >
          {product.nameKorean ? `${product.nameKorean} ${product.name}` : product.name}
          {/* Or use the following function if we needs to show chinese and japansese name when korean name is not available */}
          {/* {getProductDisplayName(product)} */}
        </div>
        {/* <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {product.nameKorean || product.nameChinese || product.nameJapanese ? //没有别名则不显示该产品tag
            <Tag color="#D0E0FF" style={{ fontSize: "10px", color: "#324873" }}>
              {product.nameKorean ? product.nameKorean : product.nameChinese ? product.nameChinese : product.nameJapanese ? product.nameJapanese : null}
            </Tag>
            : null
          }

        </div> */}
      </div>

      {/* {product.isFavourite ? (
        <StarOutlined />
      ) : null}
      {product.isScalable ? (
        <StarOutlined />
      ) : null}
      {!product.isAvailable ? (
        <div style={styles.disabledLayer}>
          <Typography style={styles.title}>
            Not Available
          </Typography>
        </div>
      ) : null} */}
    </Card >
  )
}

export default CheckoutProductCard

const styles: StyleSheet = {
  container: {
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: "lightgrey",
    overflow: "hidden",
    margin: 5
  },
  infoContainer: {
    marginTop: 10,
    marginBottom: 10,
    flexDirection: "row"
  },
  operationContainer: {
    marginTop: 5,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
    marginBottom: 5
  },
  subTitle: {
    fontSize: 13,
    fontWeight: "bold",
    color: "grey",
    textOverflow: 'ellipsis',
  },
  descRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  descText: {
    marginTop: 10,
    fontSize: 12,
    fontWeight: "400",
  },
  disabledLayer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)"
  },
  button: {
    marginLeft: 10
  }
}
