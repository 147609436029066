import { Total, CartItem, InvoiceItem, SpecialInDB, Product, ProductInDB, Category, CategoryInDB, CustomerInDB, Customer, CustomerOrderHead, Order, OrderItem, CustomerOrderItem, InvoiceHead, SpecialPrice, CartItemType, SpecialPriceType, RefundInvoiceItem } from "../models"
import API, { serverAddress, apiPrefix, imageAddress } from "../constants/API"
import { snackImage, ExampleTotal, snackImagePath } from "../constants/data"
import moment from "moment"
import { BackendPaymentMethod, PaymentMethod } from "../constants/Enums"
import { getConfiguration } from "./configurate"

//读取本地配置
const PosConfig = getConfiguration("Global")
console.log("//读取本地配置", PosConfig)
const { machineID: MachineID, warehouseCode: WarehouseCode, serverAddress: ServerAddress, port: Port } = PosConfig
/* ----- General ----- */
export function randomString(length: number) {
  var result = ''
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export async function hashString(rawString: string) {
  const utf8 = new TextEncoder().encode(rawString)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}

export function nowIsWithinTimePeriod(startDate: string, endDate: string) {
  const currentTime = moment().format('YYYY-MM-DD')//new Date()
  // const currentTime = new Date()
  // console.log('有效期比对：', startDate.substring(0, 10), endDate.substring(0, 10), currentTime, moment(startDate.substring(0, 10)).isSameOrBefore(currentTime, "day"), moment(endDate.substring(0, 10)).isSameOrAfter(currentTime))
  if (moment(startDate?.substring(0, 10)).isSameOrBefore(currentTime, "day") && moment(endDate?.substring(0, 10)).isSameOrAfter(currentTime, "day")) {
    // if (moment(startDate).isSameOrBefore(currentTime, "day") && moment(endDate).isSameOrAfter(currentTime, "day")) {
    return true
  } else {
    return false
  }
}


/* ----- API ----- */

export function generateRequestUrl(apiPath: string) {
  if (ServerAddress) {
    const portStr = Port ? `:${Port}` : ''
    return ServerAddress + portStr + apiPrefix + apiPath
  }
  return serverAddress + apiPrefix + apiPath
  // const keys = Object.keys(API)
  // const filterValue = keys.filter(key => {return type === key})
  // if (filterValue.length > 0) {
  //   const key: string = filterValue[0].toString()
  //   //@ts-ignore
  //   const apiPath = API[key]
  // }

}

export function generateImagePath(relativePath: string) {
  if (ServerAddress && relativePath) {
    const portStr = Port ? `:${Port}` : ''
    return ServerAddress + portStr + relativePath
  }
  return process.env.PUBLIC_URL + "/images/noImage.png"
  // return imageAddress + relativePath
}

export function transferPaymentMethod(paymentMethod: PaymentMethod) {
  switch (paymentMethod) {
    case PaymentMethod.Card:
      return BackendPaymentMethod.EFTPOS
    case PaymentMethod.Cash:
      return BackendPaymentMethod.Cash
    case PaymentMethod.RoyalPay:
      return BackendPaymentMethod.RoyalPay
  }
}

export function handleMultipageResponse(response: any) {
  if (Array.isArray(response)) {
    const allData: any[] = []
    response.map((item: any) => {
      // if (item.status === 200) {
      console.log("response: ", item)
      allData.concat(item.data.data)
      // }
    })
    return allData
  } else {
    return response.data.data
  }
}

/* ----- Data Transform ----- */

export const calculateRefundTotal = (refundItems: RefundInvoiceItem[]) => {
  let total = 0
  let gst = 0
  refundItems.forEach((item: RefundInvoiceItem) => {
    total += item.Price * item.planRefundQty * (1 - item.Discount / 100)
    gst += item.Price * item.planRefundQty * (1 - item.Discount / 100) * item.GSTRate / 100
    // total += item.UnitCost * item.planRefundQty * (1 - item.Discount / 100)
    // gst += item.UnitCost * item.planRefundQty * (1 - item.Discount / 100) * item.GSTRate / 100
  })
  return {
    ...ExampleTotal,
    gstAmount: gst,
    totalAmount: total,
    netAmount: Number((total - gst).toFixed(2)),
  }
}

export const cartItemsToInvoiceItems = (cartItems: Array<CartItem>) => {
  const invoiceItems: Array<InvoiceItem> = []
  cartItems.forEach((item: CartItem) => {
    const newItem = {
      // ID: item.itemId,
      Product: item.productInfo,
      ProductCode: item.productInfo.productCode,
      Qty: item.quantity,
      Price: item.total,
      Discount: item.discountRatio,
      GSTRate: item.gstRatio,
      UnitCost: item.unitPrice,
      PriceKind: `${item.type}/${item.promotionText}`,
      NetPrice: item.netPrice
    }
    //@ts-ignore
    invoiceItems.push(newItem)
  })
  return invoiceItems
}

export const cartItemsToCustomerOrderItems = (cartItems: Array<CartItem>, orderNo: string) => {
  const orderItems: Array<CustomerOrderItem> = []
  cartItems.forEach((item: CartItem) => {
    //@ts-ignore
    let newItem: CustomerOrderItem = {
      // ID: item.itemId,
      ProductCode: item.productInfo.productCode,
      Qty: item.quantity,
      Price: item.total,
      Discount: item.discountRatio,
      GSTRate: item.gstRatio,
      UnitCost: item.unitPrice,
      CustomerOrderNo: orderNo,
      PriceKind: item.type
    }
    if (item.type !== CartItemType.Normal && item.type !== CartItemType.Refund && item.specialInfo) {
      newItem = {
        ...newItem,
        StartDate: item.specialInfo.StartDate,
        EndDate: item.specialInfo.EndDate,
        Value: item.specialInfo.SpecialValue,
        Label: item.specialInfo.SpecialLabel
      }
    }
    orderItems.push(newItem)
  })
  return orderItems
}

export const cartItemsToProductCodeOnlyItems = (cartItems: Array<CartItem>) => {
  const invoiceItems: Array<InvoiceItem> = []
  let isDiscount: number = 0
  cartItems.forEach((item: CartItem) => {
    const newItem = {
      ProductCode: item.productInfo.productCode,
      Qty: item.quantity,
      // TotalPrice: item.total,
      // Price: item.total,
      Discount: item.discountRatio,
      GSTRate: item.gstRatio,
      Price: item.unitPrice,
      // UnitCost: item.unitPrice,
      PriceKind: `${item.type}/${item.promotionText}`,
      OriginalPrice: Number(item.originalPrice?.toFixed(2)),
      NetPrice: Number((item.unitPrice * (100 - (item.discountRatio ? item.discountRatio : 0)) / (100 + (item.gstRatio ? item.gstRatio : 0))).toFixed(2))
    }
    if (item.discountRatio > 0) {
      isDiscount = 1
    }
    //@ts-ignore
    invoiceItems.push(newItem)
  })
  console.log("购物车cartItems are -->", cartItems, "发票invoiceItems are -->", invoiceItems)
  return { isDiscount, productCodeOnlyItems: invoiceItems }
}
function getProductName(product: ProductInDB) {
  return product.Description +
    (product.KoreanName ? product.KoreanName :
      product.ChineseName ? product.ChineseName :
        product.Description3 ? product.Description3 :
          '')
}
export function productInDBToProduct(products: Array<ProductInDB>) {
  const transformedProducts: Array<Product> = []
  products?.forEach((product: ProductInDB) => {
    const newProduct: Product = {
      id: product.ProductCode,
      productCode: product.ProductCode,
      storeId: "store-001",
      // name: getProductName(product),
      name: product.Description,
      //price为不含税价，后续要处理
      price: product.SalesPrice1,
      // price: product.GSTStatus? Number((product.SalesPrice1*(100+product.GSTRate)/100).toFixed(2)) : product.SalesPrice1,
      categoryCode: product.CateID?.toString(),
      barCode: product.Barcode,
      nameKorean: product.KoreanName,
      nameChinese: product.ChineseName,
      nameJapanese: product.Description3,//增加日文名
      gstRate: product.GSTRate,
      // image: product.ImageLoadPath ? generateImagePath(product.ImageLoadPath) : generateImagePath(snackImagePath),
      image: product.ImageLoadPath ? generateImagePath(product.ImageLoadPath) : process.env.PUBLIC_URL + "/images/noImage.png",
      stock: product.LimitStockQty,
      hits: product.ClickRate,
      isFavourite: false,
      isAvailable: false,
      isScalable: product.Scalable === 1 ? true : false,
      isSearchable: false,
      specs: [],
      extras: [],
      createdAt: "",
      updatedAt: product.ModifyDate,
      allowDiscount: product.AllowDiscount,
      specialPrices: product.SpecialPrices,
      includeGST: product.GSTStatus,
      isShowImage: product.ShowImage ? true : false,
      // fontName?: string
      fontColor: product.FontColor,
      fontSize: product.FontSize,
      isFontBold: product.FontBold ? true : false,
      isFontItalic: product.FontItalic ? true : false,
      isFontStrikeout: product.FontStrikeout ? true : false,
      isFontUnderline: product.FontUnderline ? true : false,
      netPrice: product.SalesPrice1,
    }
    transformedProducts.push(newProduct)
  })
  return transformedProducts
}

export function categoryInDBToCategory(categories: Array<CategoryInDB>) {
  const transformedCategories: Array<Category> = []
  categories.forEach((category: CategoryInDB) => {
    if (category.ShowOnTouchPanel) {
      const newCategory: Category = {
        id: category.CateID.toString(),
        storeId: "store-001",
        categoryCode: category.CateID.toString(),
        name: category.Category,
        image: snackImage,
        createdAt: "",
        updatedAt: ""
      }
      transformedCategories.push(newCategory)
    }
  })
  return transformedCategories
}

export function orderToCustomerOrderHead(order: Order, type: "append" | "remove") {
  const customerOrderItems = cartItemsToCustomerOrderItems(order.cartItems, order.customerOrderNo)
  const newOrderInDB: CustomerOrderHead = {
    CustomerOrderNo: order.customerOrderNo,
    CustomerOrderItems: customerOrderItems,
    CustomerCode: order.customerCode,
    OrderDate: new Date().toISOString(),
    Cancel: type === "append" ? false : true,
    Holding: true,
    ModifyDate: new Date().toISOString(),
    WarehouseCode,
    Amount: Number(order.totalAmount?.toFixed(2)),
  }
  return newOrderInDB
}

export function customerInDBToCustomer(customers: Array<CustomerInDB>) {
  const transformedCustomers: Array<Customer> = []
  if (customers) {
    customers.forEach((customer: CustomerInDB) => {
      if (customer.CustomerName) {
        const newCustomer: Customer = {
          id: customer.CustomerCode,
          storeId: "STORE01",
          CustomerCode: customer.CustomerCode,
          Points: customer.LoyaltyPoints ? customer.LoyaltyPoints : 0,
          isOwner: false,
          createdAt: "",
          updatedAt: "",
          lastLogin: "",
          name: customer.CustomerName ? customer.CustomerName : "Unamed Customer",
          password: "",
          Mobile: customer.Mobile ? customer.Mobile : customer.Telephone ? customer.Telephone : customer.CustomerCode?.length === 10 ? customer.CustomerCode : "",
          deleted: false,
          allowConfigureStore: false,
          allowManageStaff: false,
          allowManageProduct: false,
          allowMakeOrder: false,
          allowCheckData: false,
          allowCashier: false,
          DefaultDiscountRate: customer.DefaultDiscountRate,
          Group: customer.Group,
          GroupName: customer.GroupName
        }
        transformedCustomers.push(newCustomer)
      }
    })
  }

  // console.log("transformed customers: ", transformedCustomers)
  return transformedCustomers
}

export const isCartItemValidSpecial = (item: CartItem, specialType: SpecialPriceType) => {
  if (item.specialInfo && nowIsWithinTimePeriod(item.specialInfo.StartDate, item.specialInfo.EndDate) && item.type.toUpperCase() === specialType) {
    return true
  } else {
    return false
  }
}

export const handleNewItemAddToCartSpecial = (originalItem: CartItem) => {
  const productInfo = originalItem.productInfo
  const onlinePrice = productInfo.price
  const onlineSpecials = productInfo.specialPrices
  let specialPrice = onlinePrice
  let type = CartItemType.Normal
  let promotionText = ""
  let specialInfo: SpecialPrice | undefined = undefined
  if (onlineSpecials && onlineSpecials.length > 0) {
    onlineSpecials.forEach((price: SpecialPrice) => {
      if (price.Live && (nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm === true)) {
        specialInfo = price
        switch (price.Type) {
          case SpecialPriceType.SpecialPrice:
            specialPrice = price.SpecialPrice!
            type = CartItemType.SpecialPrice
            promotionText = `Special Price/WAS $${onlinePrice.toFixed(2)}`
            // promotionText = `Special Price/Original: $${onlinePrice.toFixed(2)}`
            // promotionText = "Special Price"
            break
          case SpecialPriceType.BuyNGetOneFree:
            type = CartItemType.BuyNGetOneFree
            promotionText = "Promotion: Buy " + price.BuyNGetOneFree!.toString() + " Get One Free"
            break
          case SpecialPriceType.Bonus:
            type = CartItemType.Bonus
            const limitRequestQty = price ? price.BonusRequestQty : 999999
            const limitBonusQty = price ? price.BonusQty : 0
            promotionText = "Promotion1: RequestQty " + limitRequestQty?.toString() + " Get " + limitBonusQty?.toString() + " Free"
            break
          case SpecialPriceType.ReductionPrice:
            specialPrice = specialPrice - price.ReductionPrice!
            type = CartItemType.ReductionPrice
            promotionText = "Reduced Price"
            break
          case SpecialPriceType.SpecialDiscount:
            specialPrice = specialPrice * (1 - price.SpecialDiscount! / 100)
            type = CartItemType.SpecialDiscount
            promotionText = "Special Discount: " + price.SpecialDiscount!.toString() + `%/WAS $${onlinePrice.toFixed(2)}`
            break
        }
      }
    })
  }
  const total = (type === CartItemType.Normal || type === CartItemType.Refund) ? specialPrice * originalItem.quantity * (1 - originalItem.discountRatio / 100) : specialPrice * originalItem.quantity
  let newCartItem: CartItem = {
    ...originalItem,
    // productInfo: productInfo,
    // unitPrice: specialPrice,
    itemPrinted: false,
    // total: total,
    allowDiscount: type === CartItemType.Normal ? productInfo.allowDiscount : false,
    discountAmount: type === CartItemType.Normal ? originalItem.discountAmount : 0,
    discountRatio: type === CartItemType.Normal ? originalItem.discountRatio : 0,
    gstAmount: productInfo.gstRate && (originalItem.type !== CartItemType.Promotion) ? calculateGSTAmount(total, productInfo.gstRate) : 0,
    addedAt: new Date().toISOString(),
    // type: type,
    promotionText: promotionText,
    specialInfo: specialInfo
  }
  return newCartItem
}

export const appendPromotionItemsInCart = (cartItems: Array<CartItem>) => {
  const processedPromotionProducts: string[] = []
  const allPromotionItems: CartItem[] = []
  cartItems.forEach((item: CartItem) => {
    if (item.type === CartItemType.BuyNGetOneFree && item.specialInfo?.BuyNGetOneFree && !processedPromotionProducts.includes(item.productId)) {
      const quantityInCart = calculateQuantityForProduct(cartItems, item.productId)
      const numberPromotion = Math.floor(quantityInCart / item.specialInfo.BuyNGetOneFree)
      if (numberPromotion >= 1) {
        const freeItem: CartItem = {
          ...item,
          itemId: "sp",
          quantity: numberPromotion,
          type: CartItemType.Promotion,
          promotionText: "Promotion: Buy " + item.specialInfo.BuyNGetOneFree.toString() + " Get One Free",
          unitPrice: 0,
          total: 0,
          gstAmount: 0,
          discountAmount: 0,
          discountRatio: 0,
          gstRatio: 0,
          applyGst: false,
          allowDiscount: false
        }
        allPromotionItems.push(freeItem)
      }
      processedPromotionProducts.push(item.productId)
    }
  })
  return [...cartItems, ...allPromotionItems]
}

export const customerOrderItemsToCartItems = (orderItems: Array<CustomerOrderItem>) => {
  const cartItems: Array<CartItem> = []
  orderItems.forEach((item: CustomerOrderItem) => {
    // if (item.Product && item.PriceKind !== CartItemType.Promotion) {
    // console.log("discount ratio: ", item.Discount)
    //@ts-ignore
    const product: ProductInDB = item.Product
    const localProduct: Product = {
      id: product.ProductCode,
      productCode: product.ProductCode,
      storeId: "STORE01",
      name: product.Description + (product.KoreanName ? product.KoreanName : product.ChineseName ? product.ChineseName : product.Description3 ? product.Description3 : ''),
      price: product.SalesPrice1,
      categoryCode: product.CateID?.toString(),
      barCode: product.Barcode,
      nameKorean: product.KoreanName,
      nameChinese: product.ChineseName,
      gstRate: product.GSTRate,
      image: product.ImageLoadPath ? generateImagePath(product.ImageLoadPath) : snackImage,
      stock: product.LimitStockQty,
      hits: product.ClickRate,
      createdAt: product.ModifyDate,
      updatedAt: product.ModifyDate,
      allowDiscount: product.AllowDiscount,
      includeGST: product.GSTStatus,
      specialPrices: product.SpecialPrices,
      netPrice: product.SalesPrice1
    }
    const discount = item.Discount
    let newCartItem: CartItem = {
      itemId: item.ID.toString(),
      productId: item.ProductCode,
      productInfo: localProduct,
      quantity: item.Qty,
      unitPrice: item.UnitCost,
      // unitPrice: item.UnitCost,
      total: item.Price,
      applyGst: product.GSTStatus,
      allowDiscount: item.PriceKind === CartItemType.Normal ? product.AllowDiscount : false,
      discountRatio: discount,
      discountAmount: discount > 0 ? item.Qty * item.UnitCost * discount / 100 : 0,
      gstAmount: product.GSTStatus ? calculateGSTAmount(item.Price, item.GSTRate) : 0,
      gstRatio: item.GSTRate,
      addedAt: new Date().toISOString(),
      type: item.PriceKind ? item.PriceKind : CartItemType.Normal,
      rowBackground: item.PriceKind === 'promotion' ? "yellow" : "",
      // promotionText: item.promotionText
      netPrice: product.SalesPrice1
    }
    const processedNewItem = handleNewItemAddToCartSpecial(newCartItem)
    // cartItems.push(newCartItem)
    cartItems.push(processedNewItem)
    // }
  })
  // console.log('catItems 数量。。。。', cartItems)
  const result = cartItems
  // const result = appendPromotionItemsInCart(cartItems)
  return result
}

export function customerOrderHeadsToOrders(orders: Array<CustomerOrderHead>) {
  const transformedOrders: Array<Order> = []
  orders.forEach((order: CustomerOrderHead) => {
    const cartItems = customerOrderItemsToCartItems(order.CustomerOrderItems)
    const localOrder: Order = {
      id: order.CustomerOrderNo,
      customerOrderNo: order.CustomerOrderNo,
      createdAt: order.OrderDate,
      updatedAt: order.ModifyDate,
      customerName: order.Customer?.CustomerName ? order.Customer?.CustomerName : "Cash Customer",
      staffName: order.Operator?.name ? order.Operator?.name : "Supervisor",
      customerCode: order.CustomerCode,
      status: "submitted",
      hasRefund: false,
      storeId: WarehouseCode,
      // storeId: "STORE01",
      amount: 0,
      surchargeAmount: 0,
      discoutAmount: 0,
      taxAmount: 0,
      refundAmount: 0,
      totalAmount: order.Amount,
      cartItems: cartItems,
      machineId: order.Notes || MachineID,
      customer: order.Customer ? customerInDBToCustomer([order.Customer])[0] : undefined
    }
    transformedOrders.push(localOrder)
  })
  return transformedOrders
}

export function invoiceInDBsToLocalInvoices(invoiceInDBs: any) {
  const allInvoices: InvoiceHead[] = []
  console.log("查看发票", invoiceInDBs)
  invoiceInDBs.forEach((invoiceInDB: any) => {
    const points = invoiceInDB.LoyaltyPoints
    const customer: CustomerInDB = invoiceInDB.Customer
    let newInvoice: InvoiceHead = {
      ...invoiceInDB,
      CustomerName: customer?.CustomerName
    }
    if (points?.length > 0) {
      let redeemPoints = 0
      let rewardPoints = 0
      points.forEach((point: any) => {
        if (point.RedeemPoints > 0) {
          redeemPoints = point.RedeemPoints
        }
        if (point.RewardPoints > 0) {
          rewardPoints = point.RewardPoints
        }
      })
      newInvoice = {
        ...newInvoice,
        RedeemPoints: redeemPoints,
        RewardPoints: rewardPoints
      }

    }
    allInvoices.push(newInvoice)
  })
  return allInvoices
}

export function invoiceHeadsToInvoices() {

}

/* ----- Calculate ----- */

export function generateCartItemId(productCode: string) {
  const randomPattern = randomString(8)
  return "cartItem-" + productCode + randomPattern
}

export function calculateGSTAmount(salesPrice: number, gstRate: number) {
  return Number((salesPrice - salesPrice / (1 + gstRate / 100)).toFixed(2))
}

export function pointsToDollar(points: number) {
  if (points < 200) {
    return 0
  } else {
    return Math.floor(points / 200)
  }
}
export function syncModalToCustomerScreen(value: string) {
  //Modal同步到客户屏
  //@ts-ignore
  if (window?.electronAPI) {
    //@ts-ignore
    window.electronAPI.modalVisibleSync(value, (response) => {
      console.log('modal同步显示在客户屏===成功: ', response);
    })
  }
}

export function calculateCartTotal(cartItems: Array<CartItem>) {
  let amount = 0
  let netAmount = 0
  let total = 0
  let originAmount = 0
  let discount = 0
  let refund = 0
  let gst = 0
  cartItems.forEach((cartItem: CartItem) => {
    if (cartItem.applyGst) {
      gst += calculateGSTAmount(cartItem.total, cartItem.gstRatio)
    }
    if (cartItem.allowDiscount) {
      discount += cartItem.unitPrice * cartItem.quantity * cartItem.discountRatio / 100
    }
    if (cartItem.quantity < 0) {
      refund += cartItem.total
    }
    // total += cartItem.total
    amount += cartItem.unitPrice * cartItem.quantity
    originAmount += (cartItem.originalPrice ? cartItem.originalPrice : cartItem.unitPrice) * cartItem.quantity
  })
  amount = Number(amount.toFixed(2))
  discount = Number(discount.toFixed(2))
  total = Number((amount - discount).toFixed(2))
  const newTotal: Total = {
    ...ExampleTotal,
    netAmount: Number((total - gst).toFixed(2)),
    amount: Number(amount.toFixed(2)),
    discountAmount: Number(discount.toFixed(2)),
    totalAmount: Number(total.toFixed(2)),
    totalSaveAmount: Number((originAmount - total).toFixed(2)),
    totalWithGstAmount: Number(total.toFixed(2)),
    refundAmount: Number(refund.toFixed(2)),
    gstAmount: Number(gst.toFixed(2))
  }
  console.log("total计算", newTotal)
  return newTotal
}

export function calculatePaymentRounding(amount: number) {
  const decimalStr = amount.toFixed(2).split(".")[1]
  const lastDecimal = decimalStr.charAt(1)
  console.log("last decimal: ", lastDecimal)
  if (amount > 0 && lastDecimal) {
    const value = Number(lastDecimal)
    switch (value) {
      case 1:
      case 2:
        const payable = Math.floor(amount * 10) / 10
        return {
          payable: payable.toFixed(2),
          rounding: (amount - payable).toFixed(2)
        }
      case 3:
      case 4:
      case 6:
      case 7:
        const integerStr = amount.toString().split(".")[0]
        const newDecimalStr = decimalStr.charAt(0) + "5"
        const newPayable = integerStr + "." + newDecimalStr
        const newRounding = Math.abs(Number(newPayable) - amount).toFixed(2)
        return {
          payable: newPayable,
          rounding: newRounding
        }
      case 8:
      case 9:
        const payable1 = Math.ceil(amount * 10) / 10
        return {
          payable: payable1.toFixed(2),
          rounding: (payable1 - amount).toFixed(2)
        }
      default:
        return {
          payable: amount,
          rounding: 0
        }
    }
  }
  if (amount < 0 && lastDecimal) {//负值的rounding修改
    const value = Number(lastDecimal)
    switch (value) {
      case 1:
      case 2:
        const payable = Math.ceil(amount * 10) / 10
        return {
          payable: payable.toFixed(2),
          rounding: (amount - payable).toFixed(2)
        }
      case 3:
      case 4:
      case 6:
      case 7:
        const integerStr = amount.toString().split(".")[0]
        const newDecimalStr = decimalStr.charAt(0) + "5"
        const newPayable = integerStr + "." + newDecimalStr
        const newRounding = Math.abs(Number(newPayable) - amount).toFixed(2)
        return {
          payable: newPayable,
          rounding: newRounding
        }
      case 8:
      case 9:
        const payable1 = Math.floor(amount * 10) / 10
        return {
          payable: payable1.toFixed(2),
          rounding: (payable1 - amount).toFixed(2)
        }
      default:
        return {
          payable: amount,
          rounding: 0
        }
    }
  }

  return {
    payable: amount,
    rounding: 0
  }
}

export const calculateQuantityForProduct = (cartItems: Array<CartItem>, productCode: string) => {
  const filteredItems = cartItems.filter((item: CartItem) => { return (item.productInfo.productCode === productCode && item.type !== CartItemType.Promotion) })
  if (filteredItems.length > 0) {
    let totalQuantity = 0
    filteredItems.forEach((item: CartItem) => { totalQuantity += item.quantity })
    return totalQuantity
  } else {
    return 0
  }
}

export const handleAddToCartSpecial = (newItem: CartItem, cartItems: Array<CartItem>) => {
  const productInfo = newItem.productInfo
  const productCode = newItem.productId
  const specialPrices = productInfo.specialPrices || []
  
  let newCartItems = [...cartItems, newItem]
  console.log('handleAddToCartSpecial', specialPrices)
  if (specialPrices && specialPrices.length > 0) {
    // console.log("special prices: ", specialPrices)
    specialPrices.forEach((price: SpecialPrice) => {
      // console.log("each special price:", price)
      if (
        !price.OnlyForMember && //onlyformember添加
        (nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm) && price.Live && price.Type === "BuyNGetOneFree" && price.BuyNGetOneFree) {
        console.log("valid special price BuyNGetOneFree :", price)
        const limitRequestQty = price.BuyNGetOneFree
        const limitBonusQty = 1
        // const arrayProducts = [productCode]
        console.log('BuyNGetOneFree特价处理前', limitRequestQty, limitBonusQty, productCode)

        //遍历购物车，累计特价产品总数，是否满足特价要求
        let tempRequestQty = 0
        let tempBonusQty = 0
        const final = newCartItems.map((item) => {
          if (item.productId === productCode) {
            tempRequestQty += item.quantity
            if (tempRequestQty > limitRequestQty) {//开始处理送品
              tempBonusQty += item.quantity
              if (tempBonusQty <= limitBonusQty) {//当前累计小于送品数量，设为送品
                return {
                  ...item,
                  type: CartItemType.Promotion,
                  promotionText: "Promotion: Buy " + limitRequestQty.toString() + " Get One Free",
                  unitPrice: 0,
                  total: 0,
                  allowDiscount: false,
                  specialInfo: price
                }
              } else {//大于送品数量，重新计数
                tempRequestQty = item.quantity
                tempBonusQty = 0
                return {//Scan 1 , show the pormotion
                  ...item,
                  promotionText: "Promotion: Buy " + limitRequestQty.toString() + " Get One Free"
                }
              }
            }
          }
          return item
        })
        newCartItems = final
        console.log(limitRequestQty, tempRequestQty, tempBonusQty, newCartItems)
        // console.log("valid special price:", price)
        // const quantityInCart = calculateQuantityForProduct(cartItems, productCode)
        // const newQuantity = quantityInCart + newItem.quantity
        // const numberSpecial = Math.floor(newQuantity / price.BuyNGetOneFree)
        // if (numberSpecial >= 1) {
        //   const cartItemsWOSpecial = cartItems.filter((item: CartItem) => { return !(item.productInfo.productCode === productCode && item.type === "promotion") })
        //   const freeItem: CartItem = {
        //     ...newItem,
        //     itemId: "sp",
        //     quantity: numberSpecial,
        //     type: CartItemType.Promotion,
        //     // BuyNGetOneFree: price.BuyNGetOneFree,
        //     promotionText: "Promotion: Buy " + price.BuyNGetOneFree.toString() + " Get One Free",
        //     unitPrice: 0,
        //     total: 0,
        //     allowDiscount: false,
        //     specialInfo: price
        //   }
        //   const finalNewItem: CartItem = {//Scan 1 , show the pormotion
        //     ...newItem,
        //     promotionText: "Promotion: Buy " + price.BuyNGetOneFree.toString() + " Get One Free"
        //   }
        //   newCartItems = [...cartItemsWOSpecial, finalNewItem, freeItem]
        // }
      }
      //如果该产品有特价组设置
      if (
        !price.OnlyForMember && //onlyformember添加
        (nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm) && price.Live && price.Type === SpecialPriceType.Bonus && price.SpecialGroup) {
        // if (nowIsWithinTimePeriod(price.StartDate, price.EndDate) && price.Live && price.Type === "Bonus" && price.SpecialGroup) {
        console.log("valid special price group :", price)
        const limitRequestQty = price.SpecialGroup.RequestQty || 999999
        const limitBonusQty = price.SpecialGroup.BonusQty || 0
        const specialGroupType = price.SpecialGroup.Note || 'Bonus'//特价类型
        const arrayProducts = price.SpecialGroup.SpecialPrices.map((item) => item.ProductCode)
        // console.log(limitRequestQty, limitBonusQty, arrayProducts)

        //遍历购物车，累计特价组产品总数，是否满足特价要求

        //step1 找出所有特价组产品按价格排序
        const itemsSpecialGroup = newCartItems.filter(item => arrayProducts.indexOf(item.productId) > -1).sort((a, b) => a.productInfo.price - b.productInfo.price)
        let itemsSpecialCount = 0
        itemsSpecialGroup.forEach(item => {
          itemsSpecialCount += item.quantity
        })
        console.log('AnyFor0', itemsSpecialCount)
        //step2 如果是buyNgetN，计算需要设置多少赠品
        if (specialGroupType === 'AnyFor') {
          let unitSpecialPrice = Number((limitBonusQty / limitRequestQty).toFixed(2))
          let bonusCount = Math.floor(itemsSpecialCount / limitRequestQty)//anyfor批次
          let bonusEffects = bonusCount * limitRequestQty
          console.log('AnyFor1', bonusCount, specialGroupType, itemsSpecialCount, limitRequestQty, bonusEffects)
          itemsSpecialGroup.forEach(item => {
            let newItem: CartItem = item
            let newItemLeft: CartItem = item
            if (item.quantity <= bonusEffects) {//当前item数量OK，产品全部按折扣设置
              newItem = {
                ...item,
                type: CartItemType.Promotion,
                promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                unitPrice: unitSpecialPrice,
                total: unitSpecialPrice * item.quantity,
                allowDiscount: false,
                specialInfo: price
              }
              bonusEffects -= item.quantity
              console.log('AnyFor2', newItem, bonusEffects)
            } else {
              if (bonusEffects > 0) {
                //拆分超过特价的数量产品
                newItem = {
                  ...item,
                  type: CartItemType.Promotion,
                  quantity: bonusEffects,
                  promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: unitSpecialPrice,
                  total: unitSpecialPrice * bonusEffects,
                  allowDiscount: false,
                  specialInfo: price
                }
                bonusEffects = 0
                newItemLeft = {
                  ...item,
                  itemId: item.itemId + 'LEFT',
                  type: CartItemType.Promotion,
                  quantity: item.quantity - bonusEffects,
                  promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: item.originalPrice ?? 0,
                  total: item.originalPrice ? item.originalPrice * (item.quantity - bonusEffects) : 0,
                  allowDiscount: false,
                  specialInfo: price
                }
                console.log('AnyFor2归零', newItem, 0)
                newCartItems.push(newItemLeft)
              } else {
                newItem = {
                  ...item,
                  type: CartItemType.Promotion,
                  promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: item.originalPrice ?? 0,
                  total: item.originalPrice ? item.originalPrice * item.quantity : 0,
                  allowDiscount: false,
                  specialInfo: price
                }
                bonusEffects -= item.quantity
                console.log('AnyFor22', newItem, bonusEffects)
              }

            }
            newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)
            console.log('AnyFor3', newCartItems)
          })
        } else {
          let bonus = Math.floor(itemsSpecialGroup.length / (limitRequestQty + limitBonusQty)) * limitBonusQty
          bonus += Math.max(itemsSpecialGroup.length % (limitRequestQty + limitBonusQty) - limitRequestQty, 0)
          // console.log('step1 找出所有特价组产品按价格排序', itemsSpecialGroup.map(item => item.unitPrice), bonus)
          //step3 有需要改成promotion的产品，取前bonus位
          itemsSpecialGroup.forEach((item, index) => {
            let newItem: CartItem = item
            if (index < bonus) {
              newItem = {
                ...item,
                type: CartItemType.Promotion,
                promotionText: "Promotion: RequestQty " + limitRequestQty.toString() + " Get " + limitBonusQty.toString() + " Free",
                unitPrice: 0,
                total: 0,
                allowDiscount: false,
                specialInfo: price
              }
            } else {
              newItem = {
                ...item,
                promotionText: "Promotion: RequestQty " + limitRequestQty.toString() + " Get " + limitBonusQty.toString() + " Free",
                type: CartItemType.Bonus,
                unitPrice: item.productInfo.price,
                total: item.productInfo.price * item.quantity,
                // allowDiscount: true
                allowDiscount: false,//max match也不能打折
              }
            }
            //step4 置换当前items
            // console.log('step4 置换当前items', newItem)
            newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)
          })
        }
      }
      //如果该产品有TierPrice设置
      if (
        !price.OnlyForMember && //onlyformember添加
        (nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm) && price.Live && price.Type === SpecialPriceType.TierPrice) {
        
        const tierPrices = price.Rules
        console.log("valid TierPrice0 :", tierPrices)
        if (tierPrices && tierPrices.length > 0) {
          //step1 按qty从大到小排序
          // tierPrices.sort((a: any, b: any) => b.Qty - a.Qty)
          //找出满足要求的产品数
          let itemsSpecialGroup = newCartItems.filter(item => item.productId === productCode)
          // let newCarts = newCartItems.filter(item => item.productId !== productCode)
          let itemsSpecialCount = 0
          itemsSpecialGroup.forEach(item => {
            itemsSpecialCount += item.quantity
          })
          console.log('valid TierPrice1 :', itemsSpecialCount)
           
          for (let index = tierPrices.length - 1; index >= 0; index--) {
            const Tier = tierPrices[index];
          // tierPrices.forEach(Tier => {
            //循环处理tierprice
            if (Tier.Qty <= itemsSpecialCount) {
              let bonusCount = Math.floor(itemsSpecialCount / Tier.Qty)//TierPrice批次
              let bonusEffects = bonusCount * Tier.Qty
              console.log('TierPrice2', bonusCount, itemsSpecialCount, bonusEffects)
              for (let index = 0; index < bonusEffects; index++) {
                const item = itemsSpecialGroup[index]
                newItem = {
                  ...item,
                  type: CartItemType.Promotion,
                  promotionText: "Promotion: Any " + Tier.Qty.toString() + " For $" + Tier.TotalPrice.toFixed(2),
                  unitPrice: Tier.UnitPrice || Number((Tier.TotalPrice / Tier.Qty).toFixed(2)),
                  total: (Tier.UnitPrice || Number((Tier.TotalPrice / Tier.Qty).toFixed(2))) * item.quantity,
                  allowDiscount: false,
                  specialInfo: price
                }
                // newCarts.push(newItem)
                newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)
              }
              itemsSpecialGroup.splice(0, bonusEffects)
              itemsSpecialCount -= bonusEffects
            } else {
              for (let index = 0; index < itemsSpecialCount; index++) {
                const item = itemsSpecialGroup[index]
                newItem = {
                  ...item,
                  itemId: item.itemId + 'LEFT',
                  type: CartItemType.Promotion,
                  // quantity: item.quantity - bonusEffects,
                  // promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: item.originalPrice ?? 0,
                  total: item.originalPrice ? item.originalPrice * item.quantity : 0,
                  // total: item.originalPrice ? item.originalPrice * (item.quantity - bonusEffects) : 0,
                  allowDiscount: false,
                  specialInfo: price
                }
                console.log('TierPrice2归零', newItem, 0)
                // newCarts.push(newItem)
                newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)

              }
            }
          }
          // newCartItems = newCarts
        }
      }
    })
  }
  return newCartItems
}

export const handleBatchSpecial = (newItem: CartItem, cartItems: Array<CartItem>, isOnlyForMember: number) => {
  const productInfo = newItem.productInfo
  const productCode = newItem.productId
  const specialPrices = productInfo.specialPrices?.filter(sp=>(sp.OnlyForMember ?? 0) === isOnlyForMember) || []
  
  let newCartItems = [...cartItems]
  console.log('handleBatchSpecial', specialPrices)
  if (specialPrices && specialPrices.length > 0) {
    // console.log("special prices: ", specialPrices)
    specialPrices.forEach((price: SpecialPrice) => {
      // console.log("each special price:", price)
      if (
        // !price.OnlyForMember && //onlyformember添加
        (nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm) && price.Live && price.Type === "BuyNGetOneFree" && price.BuyNGetOneFree) {
        console.log("valid special price BuyNGetOneFree :", price)
        const limitRequestQty = price.BuyNGetOneFree
        const limitBonusQty = 1
        // const arrayProducts = [productCode]
        console.log('BuyNGetOneFree特价处理前', limitRequestQty, limitBonusQty, productCode)

        //遍历购物车，累计特价产品总数，是否满足特价要求
        let tempRequestQty = 0
        let tempBonusQty = 0
        const final = newCartItems.map((item) => {
          if (item.productId === productCode) {
            tempRequestQty += item.quantity
            if (tempRequestQty > limitRequestQty) {//开始处理送品
              tempBonusQty += item.quantity
              if (tempBonusQty <= limitBonusQty) {//当前累计小于送品数量，设为送品
                return {
                  ...item,
                  type: CartItemType.Promotion,
                  promotionText: (isOnlyForMember? "Promotion(Member):Buy " : "Promotion: Buy") + limitRequestQty.toString() + " Get One Free",
                  unitPrice: 0,
                  total: 0,
                  allowDiscount: false,
                  specialInfo: price
                }
              } else {//大于送品数量，重新计数
                tempRequestQty = item.quantity
                tempBonusQty = 0
                return {//Scan 1 , show the pormotion
                  ...item,
                  promotionText: (isOnlyForMember? "Promotion(Member):Buy " : "Promotion: Buy ") + limitRequestQty.toString() + " Get One Free"
                }
              }
            }
          }
          return item
        })
        newCartItems = final
        console.log(limitRequestQty, tempRequestQty, tempBonusQty, newCartItems)
      }
      //如果该产品有特价组设置
      if (
        // !price.OnlyForMember && //onlyformember添加
        (nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm) && price.Live && price.Type === SpecialPriceType.Bonus && price.SpecialGroup) {
        // if (nowIsWithinTimePeriod(price.StartDate, price.EndDate) && price.Live && price.Type === "Bonus" && price.SpecialGroup) {
        console.log("valid special price group :", price)
        const limitRequestQty = price.SpecialGroup.RequestQty || 999999
        const limitBonusQty = price.SpecialGroup.BonusQty || 0
        const specialGroupType = price.SpecialGroup.Note || 'Bonus'//特价类型
        const arrayProducts = price.SpecialGroup.SpecialPrices.map((item) => item.ProductCode)
        // console.log(limitRequestQty, limitBonusQty, arrayProducts)

        //遍历购物车，累计特价组产品总数，是否满足特价要求

        //step1 找出所有特价组产品按价格排序
        const itemsSpecialGroup = newCartItems.filter(item => arrayProducts.indexOf(item.productId) > -1).sort((a, b) => a.productInfo.price - b.productInfo.price)
        let itemsSpecialCount = 0
        itemsSpecialGroup.forEach(item => {
          itemsSpecialCount += item.quantity
        })
        console.log('AnyFor0', itemsSpecialCount)
        //step2 如果是buyNgetN，计算需要设置多少赠品
        if (specialGroupType === 'AnyFor') {
          let unitSpecialPrice = Number((limitBonusQty / limitRequestQty).toFixed(2))
          let bonusCount = Math.floor(itemsSpecialCount / limitRequestQty)//anyfor批次
          let bonusEffects = bonusCount * limitRequestQty
          console.log('AnyFor1', bonusCount, specialGroupType, itemsSpecialCount, limitRequestQty, bonusEffects)
          itemsSpecialGroup.forEach(item => {
            let newItem: CartItem = item
            let newItemLeft: CartItem = item
            if (item.quantity <= bonusEffects) {//当前item数量OK，产品全部按折扣设置
              newItem = {
                ...item,
                type: CartItemType.Promotion,
                promotionText: (isOnlyForMember? "Promotion(Member):Any " : "Promotion: Any ") + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                unitPrice: unitSpecialPrice,
                total: unitSpecialPrice * item.quantity,
                allowDiscount: false,
                specialInfo: price
              }
              bonusEffects -= item.quantity
              console.log('AnyFor2', newItem, bonusEffects)
            } else {
              if (bonusEffects > 0) {
                //拆分超过特价的数量产品
                newItem = {
                  ...item,
                  type: CartItemType.Promotion,
                  quantity: bonusEffects,
                  promotionText: (isOnlyForMember? "Promotion(Member):Any " : "Promotion: Any ") + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: unitSpecialPrice,
                  total: unitSpecialPrice * bonusEffects,
                  allowDiscount: false,
                  specialInfo: price
                }
                bonusEffects = 0
                newItemLeft = {
                  ...item,
                  itemId: item.itemId + 'LEFT',
                  type: CartItemType.Promotion,
                  quantity: item.quantity - bonusEffects,
                  promotionText: (isOnlyForMember? "Promotion(Member):Any " : "Promotion: Any ") + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: item.originalPrice ?? 0,
                  total: item.originalPrice ? item.originalPrice * (item.quantity - bonusEffects) : 0,
                  allowDiscount: false,
                  specialInfo: price
                }
                console.log('AnyFor2归零', newItem, 0)
                newCartItems.push(newItemLeft)
              } else {
                newItem = {
                  ...item,
                  type: CartItemType.Promotion,
                  promotionText: (isOnlyForMember? "Promotion(Member):Any " : "Promotion: Any ") + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: item.originalPrice ?? 0,
                  total: item.originalPrice ? item.originalPrice * item.quantity : 0,
                  allowDiscount: false,
                  specialInfo: price
                }
                bonusEffects -= item.quantity
                console.log('AnyFor22', newItem, bonusEffects)
              }

            }
            newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)
            console.log('AnyFor3', newCartItems)
          })
        } else {
          let bonus = Math.floor(itemsSpecialGroup.length / (limitRequestQty + limitBonusQty)) * limitBonusQty
          bonus += Math.max(itemsSpecialGroup.length % (limitRequestQty + limitBonusQty) - limitRequestQty, 0)
          // console.log('step1 找出所有特价组产品按价格排序', itemsSpecialGroup.map(item => item.unitPrice), bonus)
          //step3 有需要改成promotion的产品，取前bonus位
          itemsSpecialGroup.forEach((item, index) => {
            let newItem: CartItem = item
            if (index < bonus) {
              newItem = {
                ...item,
                type: CartItemType.Promotion,
                promotionText: (isOnlyForMember? "Promotion(Member):Buy " : "Promotion: Buy ") + limitRequestQty.toString() + " Get " + limitBonusQty.toString() + " Free",
                unitPrice: 0,
                total: 0,
                allowDiscount: false,
                specialInfo: price
              }
            } else {
              newItem = {
                ...item,
                promotionText: (isOnlyForMember? "Promotion(Member):Buy " : "Promotion: Buy ") + limitRequestQty.toString() + " Get " + limitBonusQty.toString() + " Free",
                type: CartItemType.Bonus,
                unitPrice: item.productInfo.price,
                total: item.productInfo.price * item.quantity,
                // allowDiscount: true
                allowDiscount: false,//max match也不能打折
              }
            }
            //step4 置换当前items
            // console.log('step4 置换当前items', newItem)
            newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)
          })
        }
      }
      //如果该产品有TierPrice设置
      if (
        !price.OnlyForMember && //onlyformember添加
        (nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm) && price.Live && price.Type === SpecialPriceType.TierPrice) {
        
        const tierPrices = price.Rules
        console.log("valid TierPrice0 :", tierPrices)
        if (tierPrices && tierPrices.length > 0) {
          //step1 按qty从大到小排序
          // tierPrices.sort((a: any, b: any) => b.Qty - a.Qty)
          //找出满足要求的产品数
          let itemsSpecialGroup = newCartItems.filter(item => item.productId === productCode)
          // let newCarts = newCartItems.filter(item => item.productId !== productCode)
          let itemsSpecialCount = 0
          itemsSpecialGroup.forEach(item => {
            itemsSpecialCount += item.quantity
          })
          console.log('valid TierPrice1 :', itemsSpecialCount)
           
          for (let index = tierPrices.length - 1; index >= 0; index--) {
            const Tier = tierPrices[index];
          // tierPrices.forEach(Tier => {
            //循环处理tierprice
            if (Tier.Qty <= itemsSpecialCount) {
              let bonusCount = Math.floor(itemsSpecialCount / Tier.Qty)//TierPrice批次
              let bonusEffects = bonusCount * Tier.Qty
              console.log('TierPrice2', bonusCount, itemsSpecialCount, bonusEffects)
              for (let index = 0; index < bonusEffects; index++) {
                const item = itemsSpecialGroup[index]
                newItem = {
                  ...item,
                  type: CartItemType.Promotion,
                  promotionText: "Promotion: Any " + Tier.Qty.toString() + " For $" + Tier.TotalPrice.toFixed(2),
                  unitPrice: Tier.UnitPrice || Number((Tier.TotalPrice / Tier.Qty).toFixed(2)),
                  total: (Tier.UnitPrice || Number((Tier.TotalPrice / Tier.Qty).toFixed(2))) * item.quantity,
                  allowDiscount: false,
                  specialInfo: price
                }
                // newCarts.push(newItem)
                newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)
              }
              itemsSpecialGroup.splice(0, bonusEffects)
              itemsSpecialCount -= bonusEffects
            } else {
              for (let index = 0; index < itemsSpecialCount; index++) {
                const item = itemsSpecialGroup[index]
                newItem = {
                  ...item,
                  itemId: item.itemId + 'LEFT',
                  type: CartItemType.Promotion,
                  // quantity: item.quantity - bonusEffects,
                  // promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: item.originalPrice ?? 0,
                  total: item.originalPrice ? item.originalPrice * item.quantity : 0,
                  // total: item.originalPrice ? item.originalPrice * (item.quantity - bonusEffects) : 0,
                  allowDiscount: false,
                  specialInfo: price
                }
                console.log('TierPrice2归零', newItem, 0)
                // newCarts.push(newItem)
                newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)

              }
            }
          }
          // newCartItems = newCarts
        }
      }
    })
  }
  return newCartItems
}

export const handleRemoveFromCartSpecial = (itemRemoved: CartItem, cartItems: Array<CartItem>) => {
  const newCartItems = cartItems.filter((item: CartItem) => item.itemId !== itemRemoved.itemId)
  const productCode = itemRemoved.productId
  console.log('handleRemoveFromCartSpecial', itemRemoved.specialInfo)
  if (itemRemoved.specialInfo?.Type === SpecialPriceType.Bonus) {//特价组产品
    const price = itemRemoved.specialInfo
    const limitRequestQty = price.SpecialGroup?.RequestQty || 999999
    const limitBonusQty = price.SpecialGroup?.BonusQty || 0
    const specialGroupType = price.SpecialGroup?.Note || 'Bonus'//特价类型
    const arrayProducts: Array<string> = price.SpecialGroup?.SpecialPrices.map((item) => item.ProductCode) || []
    // console.log(limitRequestQty, limitBonusQty, arrayProducts)

    // //遍历购物车，累计特价组产品总数，是否满足特价要求

    //step1 找出所有特价组产品按价格排序
    const itemsSpecialGroup = newCartItems.filter(item => arrayProducts.indexOf(item.productId) > -1).sort((a, b) => a.productInfo.price - b.productInfo.price)
    let itemsSpecialCount = 0
    itemsSpecialGroup.forEach(item => {
      itemsSpecialCount += item.quantity
    })
    console.log('AnyFor0', itemsSpecialCount)
    //step2 如果是buyNgetN，计算需要设置多少赠品
    if (specialGroupType === 'AnyFor') {
      let unitSpecialPrice = Number((limitBonusQty / limitRequestQty).toFixed(2))
      let bonusCount = Math.floor(itemsSpecialCount / limitRequestQty)//anyfor批次
      let bonusEffects = bonusCount * limitRequestQty
      console.log('AnyFor1', bonusCount, specialGroupType, itemsSpecialCount, limitRequestQty, bonusEffects)
      itemsSpecialGroup.forEach(item => {
        let newItem: CartItem = item
        let newItemLeft: CartItem = item
        if (item.quantity <= bonusEffects) {//当前item数量OK，产品全部按折扣设置
          newItem = {
            ...item,
            type: CartItemType.Promotion,
            promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
            unitPrice: unitSpecialPrice,
            total: unitSpecialPrice * item.quantity,
            allowDiscount: false,
            specialInfo: price
          }
          bonusEffects -= item.quantity
          console.log('AnyFor2', newItem, bonusEffects)
        } else {
          if (bonusEffects > 0) {
            //拆分超过特价的数量产品
            newItem = {
              ...item,
              type: CartItemType.Promotion,
              quantity: bonusEffects,
              promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
              unitPrice: unitSpecialPrice,
              total: unitSpecialPrice * bonusEffects,
              allowDiscount: false,
              specialInfo: price
            }
            bonusEffects = 0
            newItemLeft = {
              ...item,
              itemId: item.itemId + 'LEFT',
              type: CartItemType.Promotion,
              quantity: item.quantity - bonusEffects,
              promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
              unitPrice: item.originalPrice ?? 0,
              total: item.originalPrice ? item.originalPrice * (item.quantity - bonusEffects) : 0,
              allowDiscount: false,
              specialInfo: price
            }
            console.log('AnyFor2归零', newItem, 0)
            newCartItems.push(newItemLeft)
          } else {
            newItem = {
              ...item,
              type: CartItemType.Promotion,
              promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
              unitPrice: item.originalPrice ?? 0,
              total: item.originalPrice ? item.originalPrice * item.quantity : 0,
              allowDiscount: false,
              specialInfo: price
            }
            bonusEffects -= item.quantity
            console.log('AnyFor22', newItem, bonusEffects)
          }

        }
        newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)
        console.log('AnyFor3', newCartItems)
      })
    } else {
      let bonus = Math.floor(itemsSpecialGroup.length / (limitRequestQty + limitBonusQty)) * limitBonusQty
      bonus += Math.max(itemsSpecialGroup.length % (limitRequestQty + limitBonusQty) - limitRequestQty, 0)
      // console.log('step1 找出所有特价组产品按价格排序', itemsSpecialGroup.map(item => item.unitPrice), bonus)
      //step3 有需要改成promotion的产品，取前bonus位
      itemsSpecialGroup.forEach((item, index) => {
        let newItem: CartItem = item
        if (index < bonus) {
          newItem = {
            ...item,
            type: CartItemType.Promotion,
            promotionText: "Promotion: RequestQty " + limitRequestQty.toString() + " Get " + limitBonusQty.toString() + " Free",
            unitPrice: 0,
            total: 0,
            allowDiscount: false,
            specialInfo: price
          }
        } else {
          newItem = {
            ...item,
            promotionText: "Promotion: RequestQty " + limitRequestQty.toString() + " Get " + limitBonusQty.toString() + " Free",
            type: CartItemType.Bonus,
            unitPrice: item.productInfo.price,
            total: item.productInfo.price * item.quantity,
            // allowDiscount: true
            allowDiscount: false,//max match也不能打折
          }
        }
        //step4 置换当前items
        // console.log('step4 置换当前items', newItem)
        newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)
      })
    }
    return newCartItems
  } 
  if (itemRemoved.specialInfo?.Type === "BuyNGetOneFree" && itemRemoved.specialInfo.BuyNGetOneFree) {//常规买n送1，同产品
    const price: SpecialPrice = itemRemoved.specialInfo
    const limitRequestQty: number = price.BuyNGetOneFree || 9999
    const limitBonusQty: number = 1
    // const arrayProducts: Array<string> = price.SpecialGroup?.SpecialPrices.map((item) => item.ProductCode) || []
    console.log(limitRequestQty, limitBonusQty, productCode)

    //遍历购物车，累计特价组产品总数，是否满足特价要求
    let tempRequestQty = 0
    let tempBonusQty = 0
    const final = newCartItems.map((item) => {
      if (item.productId === productCode) {
        tempRequestQty += item.quantity
        if (tempRequestQty > limitRequestQty) {//开始处理送品
          tempBonusQty += item.quantity
          if (tempBonusQty <= limitBonusQty) {//当前累计小于送品数量，设为送品
            return {
              ...item,
              type: CartItemType.Promotion,
              promotionText: "Promotion: Buy " + limitRequestQty.toString() + " Get One Free",
              unitPrice: 0,
              total: 0,
              allowDiscount: false,
              specialInfo: price
            }
          } else {//大于送品数量，重新计数
            tempRequestQty = item.quantity
            tempBonusQty = 0
          }
        }
      }
      //获得产品原价
      return item
      // return {
      //   ...item,
      //   type: CartItemType.Normal,
      //   // promotionText: '',
      //   unitPrice: item.productInfo.includeGST && item.productInfo.gstRate? item.productInfo.price * (100+item.productInfo.gstRate)/100 : item.productInfo.price,
      //   total: item.productInfo.price * item.quantity,
      //   allowDiscount: true
      // }
    })
    return final
  }
  //如果该产品有TierPrice设置
  if (itemRemoved.specialInfo?.Type ===  SpecialPriceType.TierPrice) {
    const price = itemRemoved.specialInfo     
    const tierPrices = price.Rules
    console.log("valid TierPrice0 :", tierPrices)
    if (tierPrices && tierPrices.length > 0) {
      //step1 按qty从大到小排序
      // tierPrices.sort((a: any, b: any) => b.Qty - a.Qty)
      //找出满足要求的产品数
      let itemsSpecialGroup = newCartItems.filter(item => item.productId === productCode)
      // itemsSpecialGroup = itemsSpecialGroup.filter(item => item.itemId !== itemRemoved.itemId)
      // let newCarts = newCartItems.filter(item => item.productId !== productCode)
      let itemsSpecialCount = 0
      itemsSpecialGroup.forEach(item => {
        itemsSpecialCount += item.quantity
      })
      console.log('valid TierPrice1 :', itemsSpecialCount)
       
      for (let index = tierPrices.length - 1; index >= 0; index--) {
        const Tier = tierPrices[index];
      // tierPrices.forEach(Tier => {
        //循环处理tierprice
        if (Tier.Qty <= itemsSpecialCount) {
          let bonusCount = Math.floor(itemsSpecialCount / Tier.Qty)//TierPrice批次
          let bonusEffects = bonusCount * Tier.Qty
          console.log('TierPrice2', bonusCount, itemsSpecialCount, bonusEffects)
          for (let index = 0; index < bonusEffects; index++) {
            const item = itemsSpecialGroup[index]
            const newItem = {
              ...item,
              type: CartItemType.Promotion,
              promotionText: "Promotion: Any " + Tier.Qty.toString() + " For $" + Tier.TotalPrice.toFixed(2),
              unitPrice: Tier.UnitPrice || Number((Tier.TotalPrice / Tier.Qty).toFixed(2)),
              total: (Tier.UnitPrice || Number((Tier.TotalPrice / Tier.Qty).toFixed(2))) * item.quantity,
              allowDiscount: false,
              specialInfo: price
            }
            // newCartItems.push(newItem)
            newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)
          }
          itemsSpecialGroup.splice(0, bonusEffects)
          itemsSpecialCount -= bonusEffects
        } else {
          for (let index = 0; index < itemsSpecialCount; index++) {
            const item = itemsSpecialGroup[index]
            const newItem = {
              ...item,
              // itemId: item.itemId + 'LEFT',
              type: CartItemType.Promotion,
              // quantity: item.quantity - bonusEffects,
              promotionText: "",
              unitPrice: item.originalPrice ?? 0,
              total: item.originalPrice ? item.originalPrice * item.quantity : 0,
              // total: item.originalPrice ? item.originalPrice * (item.quantity - bonusEffects) : 0,
              allowDiscount: false,
              specialInfo: price
            }
            console.log('TierPrice2归零', newItem, 0)
            // newCartItems.push(newItem)
            newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)

          }
        }
      }
      // newCartItems = newCarts
    }
  }

  return newCartItems
}

export const handleChangeCartItemQuantity = (newItem: CartItem, cartItems: Array<CartItem>) => {
  const productInfo = newItem.productInfo
  const productCode = newItem.productId
  const specialPrices = productInfo.specialPrices
  // let promotionItemChange = false
  // let changeType: "append" | "remove" = "append"
  let newCartItems = cartItems.map((item: CartItem) => {
    if (item.itemId === newItem.itemId) {
      return newItem
    } else {
      return item
    }
  })
  if (specialPrices && specialPrices.length > 0) {
    // console.log("special prices: ", specialPrices)
    specialPrices.forEach((price: SpecialPrice) => {
      // console.log("each special price:", price)
      if ((nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm) && price.Live && price.Type === "BuyNGetOneFree" && price.BuyNGetOneFree) {
        // console.log("valid special price:", price)
        const quantityInCart = calculateQuantityForProduct(newCartItems, productCode)
        const numberSpecial = Math.floor(quantityInCart / price.BuyNGetOneFree)
        const cartItemsWOSpecial = newCartItems.filter((item: CartItem) => { return !(item.productInfo.productCode === productCode && item.type === "promotion") })

        if (numberSpecial >= 1) {
          // const cartItemsWOSpecial = newCartItems.filter((item: CartItem) => { return ! (item.productInfo.productCode === productCode &&  item.type === "promotion")})
          const freeItem: CartItem = {
            ...newItem,
            itemId: "sp",
            quantity: numberSpecial,
            type: CartItemType.Promotion,
            // BuyNGetOneFree: price.BuyNGetOneFree,
            promotionText: "Promotion: Buy " + price.BuyNGetOneFree.toString() + " Get One Free",
            unitPrice: 0,
            total: 0,
            allowDiscount: false,
            specialInfo: price
          }
          newCartItems = [...cartItemsWOSpecial, freeItem]
        } else {
          newCartItems = cartItemsWOSpecial
        }
      }
      //如果该产品有特价组设置
      if ((nowIsWithinTimePeriod(price.StartDate, price.EndDate) || price.SpecialPriceWithoutTerm) && price.Live && price.Type === SpecialPriceType.Bonus && price.SpecialGroup) {
        console.log("valid special price group :", price)
        const limitRequestQty = price.SpecialGroup.RequestQty || 999999
        const limitBonusQty = price.SpecialGroup.BonusQty || 0
        const specialGroupType = price.SpecialGroup.Note || 'Bonus'//特价类型
        const arrayProducts = price.SpecialGroup.SpecialPrices.map((item) => item.ProductCode)
        console.log(limitRequestQty, limitBonusQty, arrayProducts)

        //遍历购物车，累计特价组产品总数，是否满足特价要求
        let tempRequestQty = 0
        let tempBonusQty = 0
        let itemsSpecialCount = 0
        const itemsSpecialGroup = newCartItems.filter(item => arrayProducts.indexOf(item.productId) > -1).sort((a, b) => a.productInfo.price - b.productInfo.price)
        itemsSpecialGroup.forEach(item => {
          itemsSpecialCount += item.quantity
        })
        console.log('AnyFor0', itemsSpecialCount)
        //step2 如果是buyNgetN，计算需要设置多少赠品
        if (specialGroupType === 'AnyFor') {
          let unitSpecialPrice = Number((limitBonusQty / limitRequestQty).toFixed(2))
          let bonusCount = Math.floor(itemsSpecialCount / limitRequestQty)//anyfor批次
          let bonusEffects = bonusCount * limitRequestQty
          console.log('AnyFor1', bonusCount, specialGroupType, itemsSpecialCount, limitRequestQty, bonusEffects)
          itemsSpecialGroup.forEach(item => {
            let newItem: CartItem = item
            let newItemLeft: CartItem = item
            if (item.quantity <= bonusEffects) {//当前item数量OK，产品全部按折扣设置
              newItem = {
                ...item,
                type: CartItemType.Promotion,
                promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                unitPrice: unitSpecialPrice,
                total: unitSpecialPrice * item.quantity,
                allowDiscount: false,
                specialInfo: price
              }
              bonusEffects -= item.quantity
              console.log('AnyFor2', newItem, bonusEffects)
            } else {
              if (bonusEffects > 0) {
                //拆分超过特价的数量产品
                newItem = {
                  ...item,
                  type: CartItemType.Promotion,
                  quantity: bonusEffects,
                  promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: unitSpecialPrice,
                  total: unitSpecialPrice * bonusEffects,
                  allowDiscount: false,
                  specialInfo: price
                }
                bonusEffects = 0
                newItemLeft = {
                  ...item,
                  itemId: item.itemId + 'LEFT',
                  type: CartItemType.Promotion,
                  quantity: item.quantity - bonusEffects,
                  promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: item.originalPrice ?? 0,
                  total: item.originalPrice ? item.originalPrice * (item.quantity - bonusEffects) : 0,
                  allowDiscount: false,
                  specialInfo: price
                }
                console.log('AnyFor2归零', newItem, 0)
                newCartItems.push(newItemLeft)
              } else {
                newItem = {
                  ...item,
                  type: CartItemType.Promotion,
                  promotionText: "Promotion: Any " + limitRequestQty.toString() + " For $" + limitBonusQty.toString(),
                  unitPrice: item.originalPrice ?? 0,
                  total: item.originalPrice ? item.originalPrice * item.quantity : 0,
                  allowDiscount: false,
                  specialInfo: price
                }
                bonusEffects -= item.quantity
                console.log('AnyFor22', newItem, bonusEffects)
              }

            }
            newCartItems.splice(newCartItems.findIndex(item => item.itemId === newItem.itemId), 1, newItem)
            console.log('AnyFor3', newCartItems)
          })
        } else {
          const final = newCartItems.map((item) => {
            if (arrayProducts.indexOf(item.productId) > -1) {
              tempRequestQty += item.quantity
              if (tempRequestQty > limitRequestQty) {//开始处理送品
                tempBonusQty += item.quantity
                if (tempBonusQty <= limitBonusQty) {//当前累计小于送品数量，设为送品
                  return {
                    ...item,
                    type: CartItemType.Promotion,
                    promotionText: "Promotion: RequestQty " + limitRequestQty.toString() + " Get " + limitBonusQty.toString() + " Free",
                    unitPrice: 0,
                    total: 0,
                    allowDiscount: false,
                    specialInfo: price
                  }
                  console.log(item)
                } else {//大于送品数量，重新计数
                  tempRequestQty = item.quantity
                  tempBonusQty = 0
                  return item
                }
              } else return item
            } else return item
          })
          newCartItems = final
          console.log(limitRequestQty, tempRequestQty, tempBonusQty, newCartItems)
        }
      }
    })
  }
  return newCartItems
}

export const checkIfInvoiceContainsRefundItem = (invoiceItems: Array<InvoiceItem>) => {
  // const invoiceItems = invoice.InvoiceItems
  let containsRefundItem = false
  for (let i = 0; i < invoiceItems.length; i++) {
    if (invoiceItems[i].Qty < 0 || invoiceItems[i].RefundQty > 0) {
      containsRefundItem = true
      break
    }
  }
  return containsRefundItem
}
