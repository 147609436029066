import { InvoiceHead, InvoiceItem, Order, CartItem, CustomerOrderHead } from "../models"
import { ExampleInvoiceInDB } from "../constants/data"
import API, { pageSize } from "../constants/API"
import { generateRequestUrl } from "../utils/Global" 
import { notification } from "antd"
import axios from "axios"

// const invoiceUrl = "http://3.25.211.253:4000/api/v1/invoices"

export const postHoldingOrder = async (order: CustomerOrderHead, type: "append" | "remove") => {
  try {
    const url = generateRequestUrl(API.postHoldingOrder)
    //const config = {}
    axios.put(url, order)
    .then(response => {
      console.log("put order response: ", response)
      notification.success({
        message: type === "append" ? "Holding order created successfully!" : "Holding order deleted successfully!"
      })
    })
    .catch(error => {
      console.log("put order error: ", error)
      notification.error({
        message: type === "append" ? "Something went wrong when creating holding order!" : "Something went wrong when deleting holding order!"
      })
    })
  } catch (e) {
    console.log(e)
  }
}

export const listOrders = async () => {
  try {
    const url = generateRequestUrl(API.getHoldingOrders)
    const config = {
      params: {
        "pageSize": 10
        // "pageSize": pageSize
      }
    }
    const response = await axios.get(url, config)
    console.log('暂存order pagesize', config, response.data?.data?.length)

    return response.data?.data?.length>10? {...response.data, data:response.data.data.slice(0, 10)} : response.data
  } catch (e) {
    console.log("Something went wrong at orders!", e)
    // notification.error({
    //   message: "Something went wrong at orders!"
    // })
    return e
  }
}

// export const getAllHoldingOrders = async () => {
//   try {
//     const url = generateRequestUrl(API.getHoldingOrders)
//     const config = {}
//     // console.log("send request")
//     axios.get(url)
//     .then(response => {
//       console.log("all orders: ", response)
//     })
//     .catch(error => {
//       console.log("fetch orders error: ", error)
//     })
//   } catch (e) {
//     console.log(e)
//   }
// }