import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"
import { CartItem, InvoiceHead, InvoiceItem, Total, RefundInvoiceItem, CartItemType, SpecialPriceType } from "../../models"
import { ExampleTotal, ExampleSpecials } from "../../constants/data"
import { calculateCartTotal, calculateGSTAmount, calculateRefundTotal, handleAddToCartSpecial, handleRemoveFromCartSpecial, pointsToDollar, handleBatchSpecial } from "../../utils/Global"
import moment from "moment"

export interface CheckoutState {
  itemsInCart: Array<CartItem>
  selectedItem: CartItem | null
  total: Total
  isKeyboardVisible: boolean
  keyboardLanguage: "eng" | "kor"
  editable: any
  nextToken: string | null,
  currentOrderNo: string
  currentOrderCreatedAt: string | null
  currentOrderPos: string | null
  isRefundMode: boolean
  currentRefundInvoice: InvoiceHead | null
  refundItems: Array<RefundInvoiceItem>
  refundTotal: Total
}

const initialState = {
  itemsInCart: [],
  selectedItem: null,
  total: ExampleTotal,
  isKeyboardVisible: false,
  keyboardLanguage: "eng",
  editable: null,
  nextToken: null,
  currentOrderNo: "",
  currentOrderCreatedAt: null,
  currentOrderPos: null,
  isRefundMode: false,
  refundItems: [],
  refundTotal: ExampleTotal,
  currentRefundInvoice: null
} as CheckoutState

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    addItemToCart(state, action: PayloadAction<any>) {
      // reverse order
      // state.itemsInCart.unshift(action.payload.item)
      const newCartItems = handleAddToCartSpecial(action.payload.item, state.itemsInCart)
      state.itemsInCart = newCartItems
      state.total = calculateCartTotal(newCartItems)
      // state.itemsInCart.push(action.payload.item)
    },
    updateAllCartItems(state, action: PayloadAction<any>) {
      state.itemsInCart = action.payload.cartItems
    },
    updateCartItem(state, action: PayloadAction<any>) {
      state.itemsInCart = state.itemsInCart.map((item) =>
        item.itemId === action.payload.id
          ? { ...item, ...action.payload.input }
          : item
      )
    },
    removeItemFromCart(state, action: PayloadAction<any>) {
      const newCartItems = handleRemoveFromCartSpecial(action.payload.item, state.itemsInCart)
      state.itemsInCart = newCartItems
      state.total = calculateCartTotal(newCartItems)
      // state.itemsInCart = state.itemsInCart.filter(
      //   (item: CartItem) => item.itemId !== action.payload.id
      // )
    },
    clearCart(state, action: PayloadAction<any>) {
      state.itemsInCart = []
      state.total = ExampleTotal
    },
    updateTotal(state, action: PayloadAction<any>) {
      state.total = { ...state.total, ...action.payload.input }
      console.log('redux state.total', state.total)
    },
    showKeyboard(state, action: PayloadAction<any>) {
      state.isKeyboardVisible = action.payload.isVisible
    },
    switchKeyboardLanguage(state, action: PayloadAction<any>) {
      if (state.keyboardLanguage === "eng") {
        state.keyboardLanguage = "kor"
      } else {
        state.keyboardLanguage = "eng"
      }
    },
    revokeOrder(state, action: PayloadAction<any>) {
      state.currentOrderNo = action.payload.orderNo
      state.currentOrderCreatedAt = action.payload.createdAt
      state.currentOrderPos = action.payload.machineID
      state.itemsInCart = action.payload.itemsInCart
      state.total = action.payload.total
    },
    clearCurrentOrderNo(state, action: PayloadAction<any>) {
      state.currentOrderNo = ""
      state.currentOrderCreatedAt = null
      state.currentOrderPos = null
    },
    applyRatioDiscount(state, action: PayloadAction<any>) {
      if (typeof action.payload.discount === 'number') {
        const discountRatio = action.payload.discount / 100
        // const discountAmount = state.total.amount * discountRatio
        // const totalAmount = state.total.amount - discountAmount
        // const newTotal: Total = {
        //   ...state.total,
        //   discountAmount: discountAmount,
        //   totalAmount: totalAmount,
        //   totalWithGstAmount: totalAmount
        // }
        // state.total = newTotal
        state.itemsInCart = state.itemsInCart.map((item) =>
          item.allowDiscount
            ? {
              ...item,
              discountRatio: action.payload.discount,
              total: item.unitPrice * item.quantity * (1 - discountRatio),
              gstAmount: item.applyGst ? calculateGSTAmount(item.unitPrice * item.quantity * (1 - discountRatio), item.gstRatio) : 0
            }
            : item
        )
        const newTotal = calculateCartTotal(state.itemsInCart)
        console.log('客户组折扣结果', newTotal)
        state.total = newTotal
      }
    },
    applyRatioDiscountForBlackFriday(state, action: PayloadAction<any>) {
      console.log('黑五开始', action.payload.discount, state)
      if (typeof action.payload.discount === 'number') {
        const discountRatio = action.payload.discount / 100
        state.itemsInCart = state.itemsInCart.map((item) => {
          const isProduct = (item.productInfo.categoryCode === '46' || item.productInfo.categoryCode === '79')
          const curDate: string = moment().format('YYYY-MM-DD')
          if (item.allowDiscount && isProduct && (curDate >= '2024-11-28' && curDate <= '2024-11-29')) {
            return {
              ...item,
              allowDiscount: false,
              unitPrice: Number(Number(item.unitPrice * (1 - discountRatio)).toFixed(2)),
              // discountRatio: action.payload.discount, 
              PriceKind: `Special Price/Special Price For Black Friday/WAS $${Number(item.unitPrice).toFixed(2)}`,
              total: Number(Number(item.unitPrice * (1 - discountRatio)).toFixed(2)) * item.quantity,
              gstAmount: item.applyGst ? calculateGSTAmount(item.unitPrice * item.quantity * (1 - discountRatio), item.gstRatio) : 0,
              promotionText: `Special Price For Black Friday/WAS $${Number(item.unitPrice).toFixed(2)}`
            }
          }
          else return item
        }
        )
        const newTotal = calculateCartTotal(state.itemsInCart)
        console.log('会员黑五折扣结果', newTotal, state)
        state.total = newTotal
      }
    },
    removeRatioDiscountForBlackFriday(state, action: PayloadAction<any>) {
      console.log('黑五取消', action.payload.discount, state)
      if (typeof action.payload.discount === 'number') {
        state.itemsInCart = state.itemsInCart.map((item) => {
          const isProduct = (item.productInfo.categoryCode === '46' || item.productInfo.categoryCode === '79')
          const curDate: string = moment().format('YYYY-MM-DD')
          if (!item.allowDiscount && isProduct &&
            (curDate >= '2024-11-28' && curDate <= '2024-11-29') &&
            item.promotionText?.includes('Black Friday')) {
            return {
              ...item,
              allowDiscount: true,
              unitPrice: item.originalPrice ?? item.netPrice * (100 + (item.gstRatio ?? 0)) / 100,
              // discountRatio: action.payload.discount, 
              PriceKind: '',
              total: (item.originalPrice ?? item.netPrice * (100 + (item.gstRatio ?? 0)) / 100) * item.quantity,
              gstAmount: item.applyGst ? calculateGSTAmount((item.originalPrice ?? item.netPrice * (100 + (item.gstRatio ?? 0)) / 100) * item.quantity, item.gstRatio) : 0,
              promotionText: ''
            }
          }
          else return item
        }
        )
        const newTotal = calculateCartTotal(state.itemsInCart)
        console.log('会员黑五折扣结果', newTotal, state)
        state.total = newTotal
      }
    },
    applySpecialForMember(state) {
      console.log('onlyformember开始', state)
      state.itemsInCart = state.itemsInCart.map((item) => {
        const Specials = item.specialPrice?.filter(sp => sp.OnlyForMember)//判断是否有onlyformember特价
        if (Specials && Specials.length > 0) {
          const special = Specials[0]
          let specialPrice = item.productInfo.price || 0
          let type = CartItemType.Normal
          let typeText = ''
          let specialValue = 0
          switch (special.Type) {
            case SpecialPriceType.SpecialPrice:
              specialPrice = special.SpecialPrice || 0
              type = CartItemType.SpecialPrice
              specialValue = special.SpecialPrice || 0
              typeText = "Member Price"
              break
            case SpecialPriceType.BuyNGetOneFree:
              type = CartItemType.BuyNGetOneFree
              specialValue = special.BuyNGetOneFree || 0
              break
            case SpecialPriceType.Bonus:
              type = CartItemType.Bonus
              // specialValue = price.BuyNGetOneFree
              break
            case SpecialPriceType.ReductionPrice:
              specialPrice = specialPrice - (special.ReductionPrice || 0)
              type = CartItemType.ReductionPrice
              specialValue = special.ReductionPrice || 0
              typeText = "Member Reduction Price "
              break
            case SpecialPriceType.SpecialDiscount:
              specialPrice = specialPrice * (1 - (special.SpecialDiscount || 0) / 100)
              type = CartItemType.SpecialDiscount
              specialValue = special.SpecialDiscount || 0
              typeText = "Member Discount: " + special.SpecialDiscount!.toString() + "% Off"
              break
          }
          const promotionText = type === CartItemType.Normal ? ""
            : type === CartItemType.BuyNGetOneFree ? "Promotion: Buy " + specialValue.toString() + " Get One Free"
              : `${typeText ? typeText : "Unknown Special"}/WAS $${item.applyGst && item.gstRatio ? ((item.productInfo.price || 0) * (100 + item.gstRatio) / 100).toFixed(2) : (item.productInfo.price || 0).toFixed(2)}`
          return {
            ...item,
            allowDiscount: false,
            unitPrice: Number(item.applyGst && item.gstRatio ? (specialPrice * (100 + item.gstRatio) / 100).toFixed(2) : specialPrice.toFixed(2)),
            type,
            PriceKind: `Special Price/${promotionText}`,
            total: Number(Number(item.applyGst && item.gstRatio ? (specialPrice * (100 + item.gstRatio) / 100).toFixed(2) : specialPrice.toFixed(2))) * item.quantity,
            gstAmount: item.applyGst ? calculateGSTAmount(specialPrice, item.gstRatio) : 0,
            promotionText
          }
        } else return item
      })
      //处理mixmatch的特价
      state.itemsInCart.forEach((item) => {
        state.itemsInCart = handleBatchSpecial(item, state.itemsInCart, 1)
      })
      
      const newTotal = calculateCartTotal(state.itemsInCart)
      console.log('onlyformember结果', newTotal, state)
      state.total = newTotal

    },
    removeSpecialForMember(state) {
      console.log('取消onlyformember开始', state)
      state.itemsInCart = state.itemsInCart.map((item) => {
        const Specials = item.specialPrice?.filter(sp => !sp.OnlyForMember)//判断是否有非onlyformember特价
        if (Specials && Specials.length > 0) {
          const special = Specials[0]
          let specialPrice = item.productInfo.price || 0
          let type = CartItemType.Normal
          let typeText = ''
          let specialValue = 0
          switch (special.Type) {
            case SpecialPriceType.SpecialPrice:
              specialPrice = special.SpecialPrice || 0
              type = CartItemType.SpecialPrice
              specialValue = special.SpecialPrice || 0
              typeText = "Special Price"
              break
            case SpecialPriceType.BuyNGetOneFree:
              type = CartItemType.BuyNGetOneFree
              specialValue = special.BuyNGetOneFree || 0
              break
            case SpecialPriceType.Bonus:
              type = CartItemType.Bonus
              // specialValue = price.BuyNGetOneFree
              break
            case SpecialPriceType.ReductionPrice:
              specialPrice = specialPrice - (special.ReductionPrice || 0)
              type = CartItemType.ReductionPrice
              specialValue = special.ReductionPrice || 0
              typeText = "Reduction Price "
              break
            case SpecialPriceType.SpecialDiscount:
              specialPrice = specialPrice * (1 - (special.SpecialDiscount || 0) / 100)
              type = CartItemType.SpecialDiscount
              specialValue = special.SpecialDiscount || 0
              typeText = "Special Discount: " + special.SpecialDiscount!.toString() + "% Off"
              break
          }
          const promotionText = type === CartItemType.Normal ? ""
            : type === CartItemType.BuyNGetOneFree ? "Promotion: Buy " + specialValue.toString() + " Get One Free"
              : `${typeText ? typeText : "Unknown Special"}/WAS $${item.applyGst && item.gstRatio ? ((item.productInfo.price || 0) * (100 + item.gstRatio) / 100).toFixed(2) : (item.productInfo.price || 0).toFixed(2)}`
          return {
            ...item,
            allowDiscount: false,
            unitPrice: Number(item.applyGst && item.gstRatio ? (specialPrice * (100 + item.gstRatio) / 100).toFixed(2) : specialPrice.toFixed(2)),
            type,
            PriceKind: `Special Price/${promotionText}`,
            total: Number(Number(item.applyGst && item.gstRatio ? (specialPrice * (100 + item.gstRatio) / 100).toFixed(2) : specialPrice.toFixed(2))) * item.quantity,
            gstAmount: item.applyGst ? calculateGSTAmount(specialPrice, item.gstRatio) : 0,
            promotionText
          }
        } else return {
          ...item,
          allowDiscount: true,
          unitPrice: Number(item.applyGst && item.gstRatio ? (item.productInfo.price * (100 + item.gstRatio) / 100).toFixed(2) : item.productInfo.price.toFixed(2)),
          type: CartItemType.Normal,
          PriceKind: ``,
          total: Number(Number(item.applyGst && item.gstRatio ? (item.productInfo.price * (100 + item.gstRatio) / 100).toFixed(2) : item.productInfo.price.toFixed(2))) * item.quantity,
          gstAmount: item.applyGst ? calculateGSTAmount(item.productInfo.price, item.gstRatio) : 0,
          promotionText: ''
        }
      })
      //处理mixmatch的特价
      state.itemsInCart.forEach((item) => {
        state.itemsInCart = handleBatchSpecial(item, state.itemsInCart, 0)
      })
      const newTotal = calculateCartTotal(state.itemsInCart)
      console.log('onlyformember结果', newTotal, state)
      state.total = newTotal

    },
    applyAmountDiscount(state, action: PayloadAction<any>) {
      const discountAmount = action.payload.discount
      if (typeof discountAmount === 'number') {
        const totalAmount = state.total.amount - discountAmount
        const newTotal: Total = {
          ...state.total,
          discountAmount: discountAmount,
          totalAmount: totalAmount,
          totalWithGstAmount: totalAmount
        }
        state.total = newTotal
      }
    },
    removeDiscount(state, action: PayloadAction<any>) {
      const discountAmount = state.total.discountAmount
      if (discountAmount > 0) {
        const totalAmount = state.total.totalAmount + discountAmount
        const newTotal: Total = {
          ...state.total,
          discountAmount: 0,
          totalAmount: totalAmount,
          totalWithGstAmount: totalAmount
        }
        state.total = newTotal
        state.itemsInCart = state.itemsInCart.map((item) =>
          item.allowDiscount
            ? { ...item, discountRatio: 0, total: item.unitPrice * item.quantity }
            : item
        )
      }
    },
    redeemLoyaltyPoints(state, action: PayloadAction<any>) {
      const redeemAmount = pointsToDollar(action.payload.points)
      const totalAmount = state.total.amount - state.total.discountAmount - redeemAmount
      const netAmount = state.total.netAmount - redeemAmount
      const newTotal: Total = {
        ...state.total,
        redeemPoints: action.payload.points,
        netAmount: netAmount,
        totalAmount: totalAmount,
        totalWithGstAmount: totalAmount
      }
      state.total = newTotal
    },
    setRefundMode(state, action: PayloadAction<any>) {
      state.isRefundMode = action.payload.isRefundMode
    },
    addItemToRefundCart(state, action: PayloadAction<any>) {
      const newCartItems = [
        ...state.refundItems,
        action.payload.item
      ]
      const newTotal = calculateRefundTotal(newCartItems)
      state.refundItems = newCartItems
      state.refundTotal = newTotal
    },
    addWholeToRefundCart(state, action: PayloadAction<any>) {
      const newCartItems = action.payload.items
      const newTotal = calculateRefundTotal(newCartItems)
      state.refundItems = newCartItems
      state.refundTotal = newTotal
    },
    updateItemInRefundCart(state, action: PayloadAction<any>) {
      const oldItems = state.refundItems
      const newCartItems = oldItems.map((item: RefundInvoiceItem) => {
        if (item.refundItemId === action.payload.item.refundItemId) {
          return action.payload.item
        } else {
          return item
        }
      })
      const newTotal = calculateRefundTotal(newCartItems)
      state.refundItems = newCartItems
      state.refundTotal = newTotal
    },
    removeItemFromRefundCart(state, action: PayloadAction<any>) {
      //@ts-ignore
      const newCartItems = state.refundItems.filter((item: InvoiceItem) => item.refundItemId !== action.payload.refundItemId)
      const newTotal = calculateRefundTotal(newCartItems)
      state.refundItems = newCartItems
      state.refundTotal = newTotal
    },
    clearRefundCart(state, action: PayloadAction<any>) {
      state.refundItems = []
      state.refundTotal = ExampleTotal
    },
    setCurrentRefundInvoice(state, action: PayloadAction<any>) {
      state.currentRefundInvoice = action.payload.invoice
    },
    overWrite(state, action: PayloadAction<any>) {
      console.log("checkout overwrite是", action.payload)
      // state.itemsInCart = action.payload.itemsInCart
      // state.total = action.payload.total
      state = action.payload
    }
  },
})

export const {
  addItemToCart,
  updateCartItem,
  removeItemFromCart,
  clearCart,
  updateTotal,
  showKeyboard,
  switchKeyboardLanguage,
  revokeOrder,
  clearCurrentOrderNo,
  applyRatioDiscount,
  applyAmountDiscount,
  removeDiscount,
  redeemLoyaltyPoints,
  updateAllCartItems,
  setRefundMode,
  addItemToRefundCart,
  clearRefundCart,
  setCurrentRefundInvoice,
  removeItemFromRefundCart,
  updateItemInRefundCart,
  addWholeToRefundCart,
  overWrite,
  applyRatioDiscountForBlackFriday,
  removeRatioDiscountForBlackFriday,
  applySpecialForMember,
  removeSpecialForMember,

} = checkoutSlice.actions

export default checkoutSlice.reducer
