import moment from "moment"
import React, { useState, useEffect } from "react"
import { Layout, Menu, Breadcrumb, List, Card, Drawer, Table, Typography, Button, Tabs, message } from 'antd'
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons'
import { Product, Category, Order, CustomerOrderHead } from "../models"
import OrderDetail from "../components/Order/OrderDetail"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import SearchBar from "../components/common/SearchBar"
import { revokeOrder } from "../redux/Checkout/slice"
import { switchKeyboardLanguage, showKeyboard, searchOrder, removeHoldingOrder, loadHoldingOrders } from "../redux/Order/slice"
import { Link, useNavigate } from "react-router-dom"
import { calculateCartTotal, customerOrderHeadsToOrders, orderToCustomerOrderHead } from "../utils/Global"
import useViewport from "../hooks/useViewport"
import KeyboardModal from "../components/common/KeyboardModal"
import { dateFormat } from "../constants/data"
import ActionConfirmModal from "../components/common/ActionConfirmModal"
import { listOrders, postHoldingOrder } from "../api/order"
import "../css/Global.css"
import ScrollButtons from "../components/common/ScrollButtons"
import { serveCustomer } from "../redux/Customer/slice"
import { UserState } from "../redux/User/slice"
const { Title } = Typography

export default function HoldingOrderPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width, height } = useViewport()
  const User: UserState = useSelector((state: RootStateOrAny) => state.User)
  const { isSignIn, user } = User
  console.log('User Content: ', User)
  const Order = useSelector((state: RootStateOrAny) => state.Order)
  const { orders, selectedOrder, holdingOrders, isKeyboardVisible, searchedOrders, keyboardLanguage } = Order

  console.log("holding orders 是", holdingOrders)
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null)
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")

  const [confirmMsg, setConfirmMsg] = useState("")
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [confirmType, setConfirmType] = useState<"revoke" | "remove">("revoke")
  const [editOrder, setEditOrder] = useState<Order | null>(null)
  const [orderDisplayType, setOrderDisplayType] = useState<"search" | "all">("all")
  const [pageUpDown, setPageUpDown] = useState<number>(1)

  const fetchHoldingOrders = async () => {
    console.log('fetchHoldingOrders可能会替换holdingOrders')
    try {
      const ordersData = await listOrders()
      if (ordersData.data) {
        const orders = ordersData.data
        orders.sort((a: any, b: any) => { return a.ModifyDate > b.ModifyDate ? -1 : 1 })

        const localOrders = customerOrderHeadsToOrders(orders)
        console.log("orders: ", orders, localOrders)
        dispatch(loadHoldingOrders({ holdingOrders: localOrders }))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const addSpecials = async (items: any[]) => {
    const newItems = items?.map(item => {
      return {
        ...item,
        specialPrice: item.productInfo?.specialPrices
      }
    })
    console.log('加载holdorder明细是', newItems)
    return newItems
  }

  useEffect(() => {
    fetchHoldingOrders()
  }, [holdingOrders.length])

  const showDrawer = () => {
    setIsDrawerVisible(true)
  }

  const onClose = () => {
    setIsDrawerVisible(false)
  }

  const onPageUp = () => {
    // setPageUpDown(pageUpDown - 1)
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (!v1) return
    v1.scrollTop -= 624
  }

  const onPageDown = () => {
    // setPageUpDown(pageUpDown + 1)
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (!v1) return
    v1.scrollTop += 624
  }

  const onSearchOrder = (value: string) => {
    const result: Array<Product> = holdingOrders.filter((order: Order) => {
      return (order.customerName?.toLowerCase().includes(value.toLowerCase()) || order.customerCode?.toLowerCase().includes(value.toLowerCase())) ? true : false
    })
    // console.log("result: ", result)
    setOrderDisplayType("search")
    dispatch(searchOrder({ orders: result }))
  }

  const deleteHoldingOrder = async (order: Order) => {
    const newOrderInDB = orderToCustomerOrderHead(order, "remove")
    await postHoldingOrder(newOrderInDB, "remove")
  }

  const onConfirmRevoke = async () => {
    if (editOrder) {
      console.log("当前revoke的order是。。。", editOrder)
      const total = calculateCartTotal(editOrder.cartItems)
      const cartItems = await addSpecials(editOrder.cartItems)
      dispatch(revokeOrder(
        {
          itemsInCart: cartItems,
          total: total,
          orderNo: editOrder.customerOrderNo,
          createdAt: editOrder.createdAt,
          machineID: editOrder.machineId
        }))
      //如果有指定customer，
      if (editOrder.customer) {
        dispatch(serveCustomer({ customer: editOrder.customer }))
      }
      //revoke后删除当前holding order
      await deleteHoldingOrder(editOrder)
      dispatch(removeHoldingOrder({ orderId: editOrder.id }))
      setIsConfirmModalVisible(false)
      setEditOrder(null)
      navigate("/checkout")
    }
  }

  const onConfirmRemove = async () => {
    if (editOrder) {
      await deleteHoldingOrder(editOrder)
      dispatch(removeHoldingOrder({ orderId: editOrder.id }))
      setCurrentOrder(null)
      setEditOrder(null)
      setIsConfirmModalVisible(false)
    }
  }

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      width: "5%",
      render: (text: string, record: any, index: number) => String(index + 1).padStart(2, '0')
    },
    {
      title: "Updated Date",
      dataIndex: "updatedAt",
      width: "20%",
      render: (date: string) => <Typography>{moment(date).format(dateFormat)}</Typography>
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      width: "10%",
      render: (text: any) => <Typography style={{ textAlign: "right" }}>${text?.toFixed(2)}</Typography>
    },
    {
      title: "Machine",
      dataIndex: "machineId",
      width: "10%",
      render: (text: any) => <Typography style={{ textAlign: "center" }}>{text.split(',').length>1? text.split(',')[0] : text}</Typography>
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      width: "20%",
      render: (text: any) => <Typography>{text}</Typography>
    },
    {
      title: "Operation",
      dataIndex: "id",
      width: "25%",
      render: (id: string, item: Order) =>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>

          <button
            className="viewDetailsButton"
            style={{ width: "55px", height: "80px" }}
            // type="primary"
            // size="large" 
            onClick={() => {
              console.log("当前holding order item: ", item)
              setCurrentOrder(item)
              setIsDrawerVisible(true)
            }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/details-icon.png"} className="pageIcon" />
            View Details
          </button>
          <button
            className="printDetailsButton"
            style={{ width: "55px", height: "80px" }}
            onClick={() => {
              setConfirmMsg("Are you sure to revoke this holding order?")
              setConfirmType("revoke")
              setEditOrder(item)
              setIsConfirmModalVisible(true)
            }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/cancel-icon.png"} className="pageIcon" />
            Revoke Order
          </button>

          <button
            className="scanToRefundButton"
            style={{ width: "55px", height: "80px" }}
            onClick={() => {
              if (user && user.OrderProcessStatusMenu === 1) {
                setConfirmMsg("Are you sure to delete this holding order?")
                setConfirmType("remove")
                setEditOrder(item)
                setIsConfirmModalVisible(true)
              } else {
                message.error("No permission")
              }
            }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/delete-icon.png"} className="pageIcon" />
            Delete Order
          </button>
        </div>
    }
  ]

  const onFirstPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = 0
    }
  }
  const onLastPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
  }

  return (
    <div className="doublePageContainer" style={{ width, height }}>
      <ActionConfirmModal
        isVisible={isConfirmModalVisible}
        description={confirmMsg}
        actionText={"Yes"}
        cancelText={"No"}
        onConfirm={confirmType === "revoke" ? onConfirmRevoke : onConfirmRemove}
        onCancel={() => {
          setIsConfirmModalVisible(false)
        }}
        rightButtonDanger={confirmType === "revoke" ? false : true}
      />
      <KeyboardModal
        isVisible={isKeyboardVisible}
        language={keyboardLanguage}
        onHideKeyboard={() => {
          dispatch(showKeyboard({ isVisible: false }))
        }}
        onSwitchLanguage={() => {
          dispatch(switchKeyboardLanguage({}))
        }}
        onConfirm={(value: string) => {
          setSearchValue(value)
          dispatch(showKeyboard({ isVisible: false }))
          onSearchOrder(value)
        }}
        onSelect={(value) => {
          setSearchValue(value)
        }}
      />
      <div className="doublePage01">
        <div style={{ width: "100%", marginLeft: 10, marginRight: "-40px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "inline-flex" }}>
            <div><img src={process.env.PUBLIC_URL + "/images/assets/icons/holdingOrder-icon.png"} className="pageIcon" /></div>
            <div className="pageTitle">HOLDING ORDERS</div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="pageSearchButton"
              // type="primary" 
              onClick={() => { setOrderDisplayType("all") }}
              style={{ marginRight: 10, alignSelf: "center" }}
            >
              Show All
            </button>
            <SearchBar
              value={searchValue}
              onChange={setSearchValue}
              onSearch={onSearchOrder}
              containerStyle={{ justifyContent: "flex-end", display: "flex", width: 350, alignSelf: "center" }}
              placeholderText="Search By Customer Name/Code"
              onClick={() => {
                dispatch(showKeyboard({ isVisible: true }))
              }}
            />
          </div>
        </div>
      </div>
      <div className="doublePage03">
        <Table
          className="pageDetailTable pageMainTableHighLine pageDetailTableHeight900"
          // style={{ height: height * 0.8 }}
          rowClassName={(_, index) => {
            if (index % 2 > 0) return "rowStyle1"; else return "rowStyle2";
          }}
          columns={columns}
          dataSource={orderDisplayType === "search" ? searchedOrders : holdingOrders}
          pagination={false}

          scroll={{
            y: height * 0.84,
          }}
        />
      </div>
      <div className="doublePage05">
        <Link to="/checkout">
          <button
            className="unCheckedMenu"
            style={{ backgroundColor: "#DCE3EC" }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/return-icon.png"} className="pageIcon" />
            Return
          </button>
        </Link>
        <div style={{ width: "305px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <ScrollButtons
            onPageDown={onLastPage}
            onPageUp={onFirstPage}
            onRowDown={onPageDown}
            onRowUp={onPageUp}
          />
        </div>
        {/* <div style={{ width: "305px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <button
            className="unCheckedMenu2"
            style={{ backgroundColor: "#DCE3EC", width: "150px", display: "flex", justifyContent: "center", }}
            onClick={onPageUp}
          >
            Page Up
          </button>
          <button
            className="unCheckedMenu"
            style={{ backgroundColor: "#DCE3EC", width: "150px", display: "flex", justifyContent: "center", }}
            onClick={onPageDown}
          >
            Page Down
          </button>
        </div> */}
      </div>
      <div className="doublePage02">
        <div style={{ marginLeft: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div><img src={process.env.PUBLIC_URL + "/images/assets/icons/orderDetails-icon.png"} className="pageIcon" /></div>
          <div className="pageTitle">ORDER DETAILS</div>
        </div>
      </div>
      <div className="doublePage04">
        {
          currentOrder ?
            <OrderDetail order={currentOrder} clearCurrentOrder={() => setCurrentOrder(null)} user={user} />
            :
            <div style={{ height: "100%", backgroundColor: "white", justifyContent: "center", alignItems: "center", display: "flex", fontSize: 20 }}>No Order is Selected</div>
        }
      </div>


    </div >
  )
}
